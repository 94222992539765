import React, { PureComponent } from "react";
import { DatePicker, Form } from 'antd';
import { FormContext } from "../context";

export default class FormDatePicker extends PureComponent {
    static contextType = FormContext;

    render() {
        const {
            value,
            name,
            rules,
            label,
            placeholder,
            disabledDate
        } = this.props;
        const { form } = this.context;


        return (
            <Form.Item label={label} colon={false} className="from-date-picker">
                {form.getFieldDecorator(name, {
                    rules: rules,
                    initialValue: value
                })(
                    <DatePicker
                        placeholder={placeholder}
                        name={name}
                        disabledDate={disabledDate}
                    >
                    </DatePicker>,
                )}
            </Form.Item>
        );
    }
}
