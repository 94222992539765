import { onboardingConstants, AppConstants } from "../../constants/constants";
import cookie from "utils/cookie";
const initState = getLocalState('onboardingReducer') || {
    licenseKey: null, //save key to local 
    license: null,
    summaryInfo: null,
    superUser: null,

    //step 2
    continueComplete: false,
    locations: [],
    urls: []
}
const onboardingReducer = (state = initState, action) => {
    switch (action.type) {
        case onboardingConstants.ONBOARDING_SET_LICENSE_KEY: {
            return {
                ...state,
                licenseKey: action.payload
            }
        }
        case onboardingConstants.ONBOARDING_AUTH_LICENSE: {
            cookie.del(AppConstants.ONBOARDING_KEY);
            return {
                ...state,
                license: action.payload,
                locations: [],
                continueComplete: false,
                urls:[],
            }
        }
        case onboardingConstants.ONBOARDING_SET_SUMMARY_INFO: {
            return {
                ...state,
                summaryInfo: action.payload
            }
        }
        case onboardingConstants.ONBOARDING_SET_SUPER_USER: {
            return {
                ...state,
                superUser: action.payload
            }
        }
        case onboardingConstants.ONBOARDING_CREATE_ACCOUTN_AND_ORGANIZATION: {
            //clear
            return {
                ...state,
                licenseKey: null,
                superUser: null,
                summaryInfo: null,
            }
        }
        case onboardingConstants.ONBOARDING_CONTINUE_COMPLETE: {
            return {
                ...state,
                continueComplete: true
            }
        }
        case onboardingConstants.ONBOARDING_SET_LOCATIONS: {
            return {
                ...state,
                locations: action.payload
            }
        }
        case onboardingConstants.ONBOARDING_CREATE_LOCATIONS: {
            return {
                ...state,
                urls: action.payload
            }
        }
        case onboardingConstants.ONBOARDING_CLEAR: {
            cookie.del(AppConstants.ONBOARDING_KEY);
            return {
                licenseKey: null, 
                license: null,
                summaryInfo: null,
                superUser: null,

                continueComplete: false,
                locations: [],
                urls: []
            }
        }
        default:
            return state;
    }
}
export default (state, action) => {
    const result = onboardingReducer(state, action);
    localStorage.setItem("onboardingReducer", JSON.stringify(result));
    return result;
};




function getLocalState(key, defaultValue = null) {
    try {
        return JSON.parse(localStorage.getItem(key))
    } catch (e) {
        return defaultValue
    }
}