import { locationConstants } from '../../constants/constants';

const initState = {
    table: {},
    detail: {},
    adminGatewayDetail: {},
    contacts: [],
    floors: [],

    locationSummary: {},
    gatewaySummary: {},
    gatewayPing: {},
    gatewayHealth: {},
    gatewaySummaryList: [],

    retiredBatteries: {},
    spares: {},
    retirementHistory: {
        Device: {},
        Battery: {},
    },
};

const siteReducer = (state = initState, action) => {
    switch (action.type) {
        case locationConstants.GET_LOCATION_DETAIL: {
            return {
                ...state,
                detail: action.payload,
            };
        }
        case locationConstants.GET_ADMIN_GATEWAY_DETAIL: {
            return {
                ...state,
                adminGatewayDetail: action.payload,
            };
        }
        case locationConstants.CLEAR_LOCATION_DETAIL: {
            return {
                ...state,
                detail: {},
                contacts: [],
                floors: [],
            };
        }
        case locationConstants.LOAD_LOCATION_TABLE: {
            return {
                ...state,
                table: action.data,
            };
        }
        case locationConstants.LOAD_RETIREMENT_HISTORY_TABLE: {
            return {
                ...state,
                retirementHistory: {
                    ...state.retirementHistory,
                    [action.prop]: action.data,
                },
            };
        }
        case locationConstants.LOAD_LOCATION_SUMMARY_TABLE: {
            return {
                ...state,
                locationSummary: action.data,
            };
        }
        case locationConstants.LOAD_GATEWAY_SUMMARY_TABLE: {
            return {
                ...state,
                gatewaySummary: action.data,
            };
        } 
        case locationConstants.LOAD_GATEWAY_PING_TABLE: {
            return {
                ...state,
                gatewayPing: action.data,
            };
        }
        case locationConstants.LOAD_GATEWAY_HEALTH_TABLE: {
            return {
                ...state,
                gatewayHealth: action.data,
            };
        }

        case locationConstants.LOAD_LOCATIONS_RETIRED_BATTERIES_TABLE: {
            return {
                ...state,
                retiredBatteries: action.data,
            };
        }
        case locationConstants.LOAD_LOCATION_SPARES_TABLE: {
            return {
                ...state,
                spares: action.data,
            };
        }

        case locationConstants.LOAD_GATEWAY_SUMMARY_LIST: {
            return {
                ...state,
                gatewaySummaryList: action.payload,
            };
        }

        case locationConstants.GET_LOCATION_CONTACTS: {
            return {
                ...state,
                contacts: action.payload || [],
            };
        }
        case locationConstants.GET_LOCATION_FLOORS: {
            return {
                ...state,
                floors: action.payload || [],
            };
        }

        default:
            return state;
    }
};
export default siteReducer;
