import React, { PureComponent } from 'react';
import { Form, Switch,Tooltip } from 'antd';
import { FormContext } from "../context";
//import { Tooltip } from 'components/tooltip';

export default class FormSwitch extends PureComponent {
    static contextType = FormContext;

    render() {
        const { name, label, checked, className, onClick, disabled, tip, colon, tipclass } = this.props;
        const { form } = this.context;

        return (<Form.Item colon={colon}

            label={tip ? <Tooltip title={tip}>
                {' '}
                <span>
                    {' '}
                    {label}
                </span>
            </Tooltip> : label}


            className={`form-switch ${className || ''}`}>
            {form.getFieldDecorator(name, {
                valuePropName: 'checked',
                initialValue: checked,
            })(<Switch name={name} onClick={onClick} disabled={disabled}>{label}</Switch>)}
        </Form.Item>);
    }
}