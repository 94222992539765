import { serviceProviderConstants } from "constants/constants";

const initState = {
    associatedServiceProviders: {
    }
}

const permissionReducer = (state = initState, action) => {
    switch (action.type) {
        case serviceProviderConstants.LOAD_SERVICE_PROVIDER_TABLE: {
            return {
                ...state,
                associatedServiceProviders: action.data
            }
        }
        default:
            return state;
    }
}
export default permissionReducer;