import { deviceConstants } from "../../constants/constants";

const initState = {
    total: 0,
    devicesLoading: true,
    deviceSummary: [],
    deviceCheckInSummary:[],
    deviceSparesTotal:0,
    deviceUtilizationSummary: [],
    table: {
        filter: {},
    },
    messageTable: {
        filter: {},
    },
    detail: {}
}

const deviceReducer = (state = initState, action) => {
    switch (action.type) {
        case deviceConstants.LOAD_DEVICE_TABLE: {
            return {
                ...state,
                table: action.data
            }
        }
        case deviceConstants.SET_DEVICE_TABLE_FILTER: {
            return {
                ...state,
                table: {
                    ...state.table,
                    filter: action.data
                }
            }
        }
        case deviceConstants.LOAD_DEVICE_TOTAL: {
            return {
                ...state,
                total: action.payload
            }
        }
        case deviceConstants.LOAD_MESSAGE_TABLE: {
            return {
                ...state,
                messageTable: action.data
            }
        }
        case deviceConstants.GET_DEVICE_SUMMARY: {
            return {
                ...state,
                deviceSummary: action.payload
            }
        }
        case deviceConstants.GET_DEVICE_CHECK_IN_SUMMARY: {
            return {
                ...state,
                total: action.payload && action.payload.length > 0 ? action.payload[0] : 0,
                deviceCheckInSummary: action.payload && action.payload.length > 0 ? action.payload : [0,0,0,0,0,0,0],
                devicesLoading: false
            }
        }
        case deviceConstants.GET_DEVICE_SPARESTOTAL: {
            return {
                ...state,
                deviceSparesTotal: action.payload
            }
        }
        case deviceConstants.GET_UTILIZATION_SUMMARY: {
            return {
                ...state,
                deviceUtilizationSummary: action.payload
            }
        }
            
        case deviceConstants.GET_DEVICE_DETAIL: {
            return {
                ...state,
                detail: action.payload
            }
        }
        case deviceConstants.CLEAR_DEVICE_DETAIL: {
            return {
                ...state,
                detail: {}
            }
        }
        case deviceConstants.GET_DEVICE_DETAIL_TAGS: {
            return {
                ...state,
                detail: {
                    ...state.detail,
                    tags: action.payload
                }
            }
        }
        default:
            return state;
    }
}


export default deviceReducer;