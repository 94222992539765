import React, { PureComponent } from "react";

import { Spin } from 'antd';
import "./page-loading.scss";

export default class Loading extends PureComponent {



    render() {
        return (
            <div className="page-loading" >
                <Spin size="large" />
            </div>

        );

    }

}