import { performanceConstants } from "../../constants/constants";

const initState = {
    summary: {

    },
    callPerformance: {
        summary: [],
        aps: [],
        devices: []
    },
    droppedCall: {
        summary: [],
        aps: [],
        devices: []
    },
    longHandoff: {
        summary: [],
        aps: [],
        devices: []
    },
    packetLoss: {
        summary: [],
        aps: [],
        devices: []
    },
    lowSignalStrength:
    {
        summary: [],
        aps: [],
        devices: []
    }
}

const performanceReducer = (state = initState, action) => {
    switch (action.type) {
        case performanceConstants.GET_PERFORMANCE_SUMMARY_DATA: {
            return {
                ...state,
                summary: action.payload
            }
        }
        case performanceConstants.GET_PERFORMANCE_CALL_PERFORMANCE: {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    ...action.payload
                }
            }
        }
        case performanceConstants.GET_CALL_PERFORMANCE_SUMMARY: {
            return {
                ...state,
                callPerformance: {
                    ...state.callPerformance,
                    summary: action.payload
                }
            }
        }
        case performanceConstants.GET_CALL_PERFORMANCE_BY_AP: {
            return {
                ...state,
                callPerformance: {
                    ...state.callPerformance,
                    aps: action.payload
                }
            }
        }
        case performanceConstants.GET_CALL_PERFORMANCE_BY_DEVICE: {
            return {
                ...state,
                callPerformance: {
                    ...state.callPerformance,
                    devices: action.payload
                }
            }
        }
        case performanceConstants.GET_DROPPED_CALLS_BY_AP: {
            return {
                ...state,
                droppedCall: {
                    ...state.droppedCall,
                    aps: action.payload
                }
            }
        }
        case performanceConstants.GET_DROPPED_CALLS_BY_DEVICE: {
            return {
                ...state,
                droppedCall: {
                    ...state.droppedCall,
                    devices: action.payload
                }
            }
        }
        case performanceConstants.GET_DROPPED_CALLS_SUMMARY: {
            return {
                ...state,
                droppedCall: {
                    ...state.droppedCall,
                    summary: action.payload
                }
            }
        }


        case performanceConstants.GET_LONG_HANDOFF_SUMMARY: {
            return {
                ...state,
                longHandoff: {
                    ...state.longHandoff,
                    summary: action.payload
                }
            }
        }
        case performanceConstants.GET_LONG_HANDOFF_BY_AP: {
            return {
                ...state,
                longHandoff: {
                    ...state.longHandoff,
                    aps: action.payload
                }
            }
        }
        case performanceConstants.GET_LONG_HANDOFF_BY_DEVICE: {
            return {
                ...state,
                longHandoff: {
                    ...state.longHandoff,
                    devices: action.payload
                }
            }
        }


        case performanceConstants.GET_PACKET_LOSS_SUMMARY: {
            return {
                ...state,
                packetLoss: {
                    ...state.packetLoss,
                    summary: action.payload
                }
            }
        }
        case performanceConstants.GET_PACKET_LOSS_BY_AP: {
            return {
                ...state,
                packetLoss: {
                    ...state.packetLoss,
                    aps: action.payload
                }
            }
        }
        case performanceConstants.GET_PACKET_LOSS_BY_DEVICE: {
            return {
                ...state,
                packetLoss: {
                    ...state.packetLoss,
                    devices: action.payload
                }
            }
        }


        case performanceConstants.GET_LOW_SIGNAL_STRENG_SUMMARY: {
            return {
                ...state,
                lowSignalStrength: {
                    ...state.lowSignalStrength,
                    summary: action.payload
                }
            }
        }
        case performanceConstants.GET_LOW_SIGNAL_STRENG_BY_AP: {
            return {
                ...state,
                lowSignalStrength: {
                    ...state.lowSignalStrength,
                    aps: action.payload
                }
            }
        }
        case performanceConstants.GET_LOW_SIGNAL_STRENG_BY_DEVICE: {
            return {
                ...state,
                lowSignalStrength: {
                    ...state.lowSignalStrength,
                    devices: action.payload
                }
            }
        }


        default:
            return state;
    }
}
export default performanceReducer;