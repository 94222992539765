import React, { PureComponent } from 'react';
import { Select, Form } from 'antd';
import { FormContext } from '../context';
const { Option } = Select;

export default class FormSelect extends PureComponent {
    static contextType = FormContext;

    setFieldsValue = value => {
        this.context.form.setFieldsValue({
            [this.props.name]: value
        });
    };
    getPlaceholder = () => {
        const { placeholder, optional } = this.props;
        if (optional === true) {
            return '--Please Choose--';
        }
        return optional ? optional : placeholder || '--Please Choose--';
    };
    componentDidMount() {
        this.props.onRef && this.props.onRef(this);
    }

    render() {
        const {
            options,
            value,
            name,
            rules,
            label,
            placeholder,
            onChange,
            colon,
            style,
            defaultOption,
            disabled,
            tip,
            className
        } = this.props;
        const { form } = this.context;
        return (
            <Form.Item
                label={label}
                className={'form-select ' + className}
                colon={colon === false ? false : true}
            >
                {form.getFieldDecorator(name, {
                    rules: rules,
                    initialValue:
                        value || value === 0 || value === false ? value : ''
                })(
                    <Select
                        disabled={disabled}
                        showSearch
                        placeholder={placeholder || this.getPlaceholder()}
                        name={name}                        
                        style={style}
                        onChange={onChange}
                        filterOption={(input, option) => {
                            return (
                                option.props.children &&
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            );
                        }}
                    >
                        {defaultOption && (
                            <Option value={''}>--Please Choose--</Option>
                        )}

                        {options &&
                            options.map(option => (
                                <Option key={option.value} value={option.value}>
                                    {option.text}
                                </Option>
                            ))}
                            {this.props.children && this.props.children}
                    </Select>
                )}
                {tip && <small className="form-item-tip">{tip}</small>}
            </Form.Item>
        );
    }
}
