import React, { PureComponent } from 'react';
import { Form, Spin } from 'antd';
import { FormContext } from '../context';

class FormContainer extends PureComponent {
    submiting = false;

    constructor(props) {
        super(props);
        this.state = {
            sping: false,
        };
        props.onRef && props.onRef(this);
    }

    onSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const options = {
            scroll: {
                offsetTop: 120,
            },
        };

        this.props.form.validateFieldsAndScroll(
            null,
            options,
            async (err, values) => {
                if (!err && !this.submiting) {
                    this.submiting = true;

                    if (this.props.loading) {
                        this.setState({ sping: true });
                    }

                    try {
                        await this.props.onSubmit(values);
                    } catch (e) {}
                    this.submiting = false;

                    if (this.props.loading) {
                        this.setState({ sping: false });
                    }
                }
            }
        );
        // if(this.props.isReset){
        // this.props.form.resetFields();
        // }
    };

    clearValues = () => {
        const { form } = this.props;
        const values = this.props.form.getFieldsValue();

        Object.keys(values).forEach((key) => {
            values[key] = '';
        });
        form.setFieldsValue(values);
    };

    render() {
        const { form, children, className, layout } = this.props;
        return (
            <Spin spinning={this.state.sping}>
                <Form
                    onSubmit={this.onSubmit}
                    layout={layout}
                    className={className}
                >
                    <FormContext.Provider
                        value={{
                            form: form,
                        }}
                    >
                        {children}
                    </FormContext.Provider>
                </Form>
            </Spin>
        );
    }
}

export default Form.create()(FormContainer);
