import React from "react";

export default function FormItemContainer(props) {
    const { label, required, className } = props;
    return (<div className={"ant-form-item form-item-container " + className + (required ? " required" : "")} >
        <label className="ant-form-item-label">{label}</label>
        <div className="ant-form-item-control-wrapper">{props.children}</div>
    </div>)
}


