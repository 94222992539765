import React, { PureComponent } from 'react';
import { Modal, Button, Spin } from 'antd';
//import { FormattedMessage } from 'react-intl';
export default class DeleteBtn extends PureComponent {
    state = {
        spinning: false,
    };

    onConfirm = async () => {
        const { text } = this.props;

        Modal.confirm({
            title: text || 'Are you sure you want to delete this record? ',
            className: 'delete-confirm',
            okText: 'Confirm',
            okButtonProps: { id: "btn_confirm_ok" },
            cancelButtonProps: { id: "btn_confirm_cancel" },
            onOk: async () => {
                try {
                    this.setState({ spinning: true });
                    await this.props.onConfirm();
                } catch (e) {
                    console.log(e);
                }
                this.setState({ spinning: false });
            },
        });
    };

    render() {
        const { label, isContainer, name } = this.props;
        return (
            <Spin spinning={this.state.spinning}>
                <Button
                    name={name || 'btn_confirm'}
                    type="danger"
                    onClick={this.onConfirm}
                    className={isContainer ? ' confirm-container' : ''}
                >
                    {label || "Delete"}
                </Button>
            </Spin>
        );
    }
}
