
const initState = {
    province: [],
    country: [],
    group: [],
}

const cacheReducer = (state = initState, action) => {
    switch (action.type) {
        default:
            return state;
    }
}


export default cacheReducer;