import React, { useCallback, lazy, Suspense } from 'react';
import ENUS from './items/en-us';

const FRFR = lazy(() =>
    import(/* webpackChunkName: "local_fr-FR" */ './items/fr-fr'),
);
const DEDE = lazy(() =>
    import(/* webpackChunkName: "local_de-DE" */ './items/de-de'),
);

const ESES = lazy(() =>
    import(/* webpackChunkName: "local_es-ES" */ './items/es-es'),
);
const ITIT = lazy(() => import('./items/it-it'));

const NLNL = lazy(() => import('./items/nl-nl'));

function Source(props) {
    const { local, updateLanguageSource, languageTexts } = props;

    const addLocal = useCallback(
        (source) => {
            updateLanguageSource(local, source);
        },
        [updateLanguageSource, local],
    );
    switch (local) {
        case 'de-DE':
            return (
                <DEDE
                    addLocal={addLocal}
                    languageTexts={languageTexts['de-DE']}
                />
            );
        case 'en-US':
            return (
                <ENUS
                    addLocal={addLocal}
                    languageTexts={languageTexts['en-US']}
                />
            );
        case 'es-ES':
            return (
                <ESES
                    addLocal={addLocal}
                    languageTexts={languageTexts['es-ES']}
                />
            );
        case 'fr-FR':
            return (
                <FRFR
                    addLocal={addLocal}
                    languageTexts={languageTexts['fr-FR']}
                />
            );
        case 'it-IT':
            return (
                <ITIT
                    addLocal={addLocal}
                    languageTexts={languageTexts['it-IT']}
                />
            );
        case 'nl-NL':
            return (
                <NLNL
                    addLocal={addLocal}
                    languageTexts={languageTexts['nl-NL']}
                />
            );
        default:
            return (
                <ENUS
                    addLocal={addLocal}
                    languageTexts={languageTexts['en-US']}
                />
            );
    }
}

export default function Intl(props) {
    const { children, ...other } = props;
    return (
        <>
            <Suspense fallback={<></>}>
                <Source {...other} />
            </Suspense>
            {children}
        </>
    );
}
