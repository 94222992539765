import { combineReducers } from 'redux';

import { AppConstants } from 'constants/constants';

import appReducer from './reducers/appReducer';
import accountReducer from './reducers/accountReducer';

import alertReducer from './reducers/alertReducer';
import deviceReducer from './reducers/deviceReducer';
import batteryReducer from './reducers/batteryReducer';
import locationReducer from './reducers/locationReducer';
import userReducer from './reducers/userReducer';
import basicReducer from './reducers/basicReducer';
import commonReducer from './reducers/commonReducer';
import performanceReducer from './reducers/performanceReducer';

import licensesReducer from './reducers/licensesReducer';
import onboardingReduces from './reducers/onboardingReduces';
import notificationReducer from './reducers/notificationReducer';
import organizationReducer from './reducers/organizationReducer';
import adminUserReducer from './reducers/adminUserReducer';

import cacheReducer from './reducers/cacheReducer';

import administrationReducer from './reducers/administration/reducers';

import { initState as appInitState } from './reducers/appReducer';

const rootReducer = combineReducers({
    app: appReducer,
    account: accountReducer,

    alert: alertReducer,
    location: locationReducer,
    device: deviceReducer,
    battery: batteryReducer,

    basic: basicReducer,
    user: userReducer,
    cache: cacheReducer,
    common: commonReducer,
    performance: performanceReducer,

    organizations: organizationReducer,
    licenses: licensesReducer,
    onboarding: onboardingReduces,
    notification: notificationReducer,

    adminUsers: adminUserReducer,

    administration: administrationReducer,
});

export default (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = {
            app: {
                ...appInitState,
                init: true,
            },
        };
    } else if (
        action.type === 'CLEAR_DATA' ||
        action.type === AppConstants.INIT_DATA
    ) {
        state = {
            app: state.app,
            account: state.account,
        };
    }
    return rootReducer(state, action);
};
