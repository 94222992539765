import { createSelector } from 'reselect';

import {
    globalConstants,
    dateFormatConstants,
    chartConstants,
} from '../constants/constants';
import {
    getVersityVersion,
    /*deviceModelToFormat*/
} from '../utils';
import moment from 'moment';

export const getAppState = (state) => state.app;
export const getAccountState = (state) => state.account;
export const getUserState = (state) => state.user;
export const getAlertState = (state) => state.alert;
export const getDeviceState = (state) => state.device;
export const getLocationState = (state) => state.location;
export const getBatteryState = (state) => state.battery;
export const getBasicState = (state) => state.basic;
export const getCommonState = (state) => state.common;
export const getPerformanceState = (state) => state.performance;

export const getLicensesState = (state) => state.licenses;
export const getOrganizationsState = (state) => state.organizations;
export const getOnboardingState = (state) => state.onboarding;
export const getAdminUserState = (state) => state.adminUsers;

export const getDeviceOrBatteryState = (state) =>
    state.administration.devicesOrButteries;

//app
export const getAppTitleState = createSelector(
    [getAppState],
    (app) => app.title
);
export const getNotificationTimeRange = createSelector(
    [getAppState],
    (app) => app.notificationTimeRange
);
export const getAppMenusState = createSelector(
    [getAppState],
    (app) => app.menus
);
export const getCurrentActiveMenuState = createSelector(
    [getAppState],
    (app) => app.currentActiveMenu
);
export const getCustomersState = createSelector(
    [getAppState],
    (app) => app.customers
);
export const getSitesState = createSelector([getAppState], (app) => app.sites);
export const getCurrentCustomerState = createSelector(
    [getAppState],
    (app) => app.currentCustomer
);
export const getCurrentOrganizationState = createSelector(
    [getAppState],
    (app) => app.organization
);
export const getAdminAcountState = createSelector(
    [getAppState],
    (app) => app.adminAcount
);
export const getAdminOrganizationState = createSelector(
    [getAppState],
    (app) => app.adminOrganization
);
export const getCurrentCurrentAccountIdState = createSelector(
    [getAppState],
    (app) => app.currentAccountId
);
export const getCurrentLocationState = createSelector(
    [getAppState],
    (app) => app.location
);
export const getLanguageState = createSelector(
    [getAppState],
    (app) => app.language
);
export const getLanguageTexts = createSelector([getAppState], (app) => {
    return app.languageTexts;
});
export const getMaintennance = createSelector(
    [getAppState],
    (app) => app.maintennance
)
//-- account
export const getCurrentAccountState = createSelector(
    [getAccountState],
    (account) => account.current
);
export const getAccountPermissionState = createSelector(
    [getAccountState],
    (account) => {
        if (account.current) {
            return account.current.permissions;
        }
        return [];
    }
);
export const getPermissionTreeState = createSelector(
    [getAccountState],
    (account) => account.permissions
);
export const checkPermission = (state) => {
    return (code) => {
        const permissions = getAccountPermissionState(state);
        if (permissions.length > 0) {
            // debugger
        }
        return permissions.includes(code);
    };
};
export const getUserTimezone = createSelector(
    [getCurrentAccountState],
    (account) => {
        if (account && account.timezone) {
            return account.timezone;
        }
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
);
export const getUserDateFormat = createSelector(
    [getCurrentAccountState],
    (account) => {
        if (account && account.dateFormat) {
            return account.dateFormat;
        }
        return dateFormatConstants.DATE;
    }
);
export const getUserTimeFormat = createSelector(
    [getCurrentAccountState],
    (account) => {
        if (account && account.timeFormat) {
            return account.timeFormat;
        }
        return '24h';
    }
);

export const getUserTimeDisplayFormat = createSelector(
    [getUserTimeFormat],
    (timeFormat) => {
        if (timeFormat === '12h') {
            return 'hh';
        }
        return 'HH';
    }
);

export const getUserTimeFormat_HHMMSS = createSelector(
    [getUserTimeFormat],
    (timeFormat) => {
        if (timeFormat === '12h') {
            return 'hh:mm:ss A';
        }
        return 'HH:mm:ss A';
    }
);

export const getSpectralinkAdminState = createSelector([getAppState], (app) => {
    return app.spectralinkAdmin;
});

export const getColorPaletteState = createSelector(
    [getCurrentAccountState],
    (accountBranding) => {
        return [
            accountBranding.primaryColor,
            accountBranding.secondaryColor,
            accountBranding.tertiaryColor,
            accountBranding.quaternaryColor,
            ...chartConstants.COLORS,
        ];
    }
);
export const getAppLogo = createSelector(
    [getCurrentAccountState],
    (accountBranding) => {
        if (accountBranding) {
            return accountBranding.appLogo || '/images/logo.png';
        }
    }
);

export const getAccountTableData = createSelector(
    [getAccountState],
    (account) => {
        return account.table;
    }
);
//--alert

export const getAlertNotificationsState = createSelector(
    [getAlertState],
    (alerts) => {
        return alerts.notifications;
    }
);
export const getAlertRulesState = createSelector([getAlertState], (alerts) => {
    return alerts.rules;
});
export const getRuleOperationsDefinitionsState = createSelector(
    [getAlertState],
    (alerts) => {
        return alerts.ruleOperationsDefinitions;
    }
);
export const getCriteriaDefinitionsState = createSelector(
    [getAlertState],
    (alerts) => {
        return alerts.criteriaDefinitions;
    }
);

//notification
export const getEmialNotificationDetailState = (state) =>
    state.notification.emailNotificationDetail;
export const getSmsNotificationDetailState = (state) =>
    state.notification.smsNotificationDetail;
export const getRoleTableState = (state) => getUserState(state).roleTable;
//location

export const getLocationTableState = (state) => getLocationState(state).table;
export const getLocationDetailState = (state) => getLocationState(state).detail;
export const getLocationContactsState = (state) =>
    getLocationState(state).contacts;
export const getLocationFloorsState = (state) => getLocationState(state).floors;
export const getLocationSummaryState = (state) =>
    getLocationState(state).locationSummary;
export const getGatewaySummaryState = (state) =>
    getLocationState(state).gatewaySummary;
export const getGatewayPingState = (state) =>
    getLocationState(state).gatewayPing;
export const getGatewayHealthState = (state) =>
    getLocationState(state).gatewayHealth;
export const getGatewaySummaryListState = (state) =>
    getLocationState(state).gatewaySummaryList;
export const getLocationsRetiredBatteriesState = (state) =>
    getLocationState(state).retiredBatteries;
export const getAdminGateWayDetailState = (state) =>
    getLocationState(state).adminGatewayDetail;
export const getLocationSparesState = (state) => getLocationState(state).spares;
export const getRetirementHistoryState = (state) =>
    getLocationState(state).retirementHistory;

//device
export const getDeviceTotalState = (state) => getDeviceState(state).total;
export const getDevicesLoading = (state) => getDeviceState(state).devicesLoading;
export const getDeviceSummaryState = (state) =>
    getDeviceState(state).deviceSummary;
export const getDeviceCheckInSummaryState = (state) =>
    getDeviceState(state).deviceCheckInSummary;
export const getDeviceSparesTotalState = (state) =>
    getDeviceState(state).deviceSparesTotal;
export const getDeviceUtilizationSummaryState = (state) =>
    getDeviceState(state).deviceUtilizationSummary;

export const getDevice24CheckInPctState = createSelector(
    [getDeviceCheckInSummaryState],
    (deviceCheckIns) => {
        if (deviceCheckIns) {
          return parseFloat(100.0 * deviceCheckIns[1] / deviceCheckIns[0]).toFixed(2)
        }
        return 0;
    }
);

export const getDeviceDistributionSummaryChartState = createSelector(
    [getDeviceCheckInSummaryState, getDeviceSummaryState],
    ([total], deviceSummary) => {
        let summary = {
            labels: [],
            values: [],
        };
        if (deviceSummary) {
            let deviceDistribution = {};

            deviceSummary.forEach((item) => {
                const model = item.device_model; // deviceModelToFormat(item.device_model);

                if (deviceDistribution[model]) {
                    deviceDistribution[model]++;
                } else {
                    deviceDistribution[model] = 1;
                }
            });

            if (total > deviceSummary.length) {
                deviceDistribution[globalConstants.UNKOWN] =
                    total - deviceSummary.length;
            }
            summary.labels = Object.keys(deviceDistribution);
            summary.values = Object.values(deviceDistribution);
        }
        return summary;
    }
);
export const getDeviceSpectralinkSoftwareSummaryChartState = createSelector(
    [getDeviceCheckInSummaryState, getDeviceSummaryState],
    ([total], deviceSummary) => {
        let summary = {
            labels: [],
            values: [],
        };
        if (deviceSummary) {
            let spectralinkSoftware = {};
            deviceSummary.forEach((item) => {
                let version = '';
                // if (item.device_model && item.device_model.startsWith('84')) {
                //     version =
                //         (item.device_sw_revision &&
                //             item.device_sw_revision.split('.')[3]) ||
                //         item.device_sw_revision;
                // } else {
                version = getVersityVersion(item.device_sw_revision);
                // }
                if (spectralinkSoftware[version]) {
                    spectralinkSoftware[version]++;
                } else {
                    spectralinkSoftware[version] = 1;
                }
            });
            if (total > deviceSummary.length) {
                spectralinkSoftware[globalConstants.UNKOWN] =
                    total - deviceSummary.length;
            }
            summary.labels = Object.keys(spectralinkSoftware);
            summary.values = Object.values(spectralinkSoftware);
        }
        return summary;
    }
);
export const getDeviceAndroidOSSummaryChartState = createSelector(
    [getDeviceCheckInSummaryState, getDeviceSummaryState],
    ([total], deviceSummary) => {
        let summary = {
            labels: [],
            values: [],
        };
        if (deviceSummary) {
            let androidOS = {};
            deviceSummary.forEach((item) => {
                let version = item.device_os_revision;
                if (item.device_model && item.device_model.startsWith('84')) {
                    if (item.device_sw_revision) {
                        const v = item.device_sw_revision.split('.');
                        if (v.length === 4) {
                            version = v[0] + '.' + v[1];
                        }
                        // const v = item.device_sw_revision.split('.')[3];
                        // if (v) {
                        //     version = item.device_sw_revision.replace(new RegExp(`.${v}.${v}$`), '');
                        // }
                    }
                }
                if (androidOS[version]) {
                    androidOS[version]++;
                } else {
                    androidOS[version] = 1;
                }
            });
            if (total > deviceSummary.length) {
                androidOS[globalConstants.UNKOWN] =
                    total - deviceSummary.length;
            }
            summary.labels = Object.keys(androidOS);
            summary.values = Object.values(androidOS);
        }
        return summary;
    }
);
export const getDeviceCheckInSummaryChartState = createSelector(
    [getDeviceCheckInSummaryState],
    (deviceCheckIns) => {
        let summary = {
            labels: [],
            values: [],
        };
        if (deviceCheckIns) {
            const checkInSinceDays = {
                '<24h': deviceCheckIns[1],
                '1-2d': deviceCheckIns[2],
                '2-3d': deviceCheckIns[3],
                '3-7d': deviceCheckIns[4],
                '>7d': deviceCheckIns[5],
            };

            summary.labels = Object.keys(checkInSinceDays);
            summary.values = Object.values(checkInSinceDays);
        }
        return summary;
    }
);
export const getDeviceStatusSummaryChartState = createSelector(
    [getDeviceCheckInSummaryState,getDeviceSparesTotalState],
    (deviceCheckIns,total) => {
        let summary = {
            labels: [],
            values: [],
        };
        if (deviceCheckIns) {
            const deviceStatus = {
                Active: deviceCheckIns[6],
                'Out of contact': deviceCheckIns[5],
                Spares: total,
            };

            summary.labels = Object.keys(deviceStatus);
            summary.values = Object.values(deviceStatus);
        }
        return summary;
    }
);

//battery
export const getBatteryTotalState = (state) => getBatteryState(state).total;
export const getBatteriesLoading = (state) => getBatteryState(state).batteriesLoading;
export const getBatteryStatusSummaryState = (state) =>
    getBatteryState(state).summary.batteryStatus;
export const getBatteryCheckInSummaryState = (state) =>
    getBatteryState(state).summary.batteryCheckIn;

//-- basic
export const getDataDictionaryState = (state) => state.basic.dataDictionary;
// When redux is needed to recalculate a result, createSelector is used to generate the result
//export const getExampleState = createSelector(
//    [getBasicState],
//    (basic) => ({ ...basic.dataDictionary, xx: 11 })
//)

// common
export const getDeviceModelsState = (state) =>
    getCommonState(state).deviceModels;
export const getDeviceVersityVersionsState = (state) =>
    getCommonState(state).versityVersions;
export const getAlertStatusState = (state) => getCommonState(state).alertStatus;
export const getAlertTypesState = (state) => getCommonState(state).alertTypes;
export const getAlertCountState = (state) => getCommonState(state).alertCount;
export const getLocationsState = (state) => getCommonState(state).locations;
export const getAccountLocationsState = (state) =>
    getCommonState(state).accountLocations;
export const getSettingsList = (state) => getCommonState(state).setingsList;
export const getSSIDSByTenantIdState = (state) => getCommonState(state).SSIDS;
export const getOrganizationOptionsState = (state) =>
    getCommonState(state).organizations;
export const getRolesOptionsState = (state) => getCommonState(state).roles;
export const getAWSRegionOptionsState = (state) =>
    getCommonState(state).awsRegions;
export const getDeviceTagsState = (state) => getCommonState(state).deviceTags;
export const getTimeZoneOptionsState = (state) =>
    getCommonState(state).timeZones;
export const getCountryOptionsState = (state) =>
    getCommonState(state).countries;
export const getSubdivisionOptionsState = (state) =>
    getCommonState(state).subdivisions;
export const getAccountOptionsState = (state) => getCommonState(state).accounts;
// getPerformanceState
//export const getCallPerformanceState = (state) => getPerformanceState(state).summary.callPerformance;
export const getPerformanceSummaryState = (state) =>
    getPerformanceState(state).summary;
export const getCallPerformanceState = (state) =>
    getPerformanceState(state).callPerformance;
export const getPerformance_DroppedCallState = (state) =>
    getPerformanceState(state).droppedCall;
export const getPerformance_LongHandoffState = (state) =>
    getPerformanceState(state).longHandoff;
export const getPerformance_PacketLossState = (state) =>
    getPerformanceState(state).packetLoss;
export const getPerformance_LowSignalStrengthState = (state) =>
    getPerformanceState(state).lowSignalStrength;

export const getDailyCallPerformanceChartState = createSelector(
    [getPerformanceSummaryState],
    (summary) => {
        const { dailyCallPerformance } = summary;

        if (!dailyCallPerformance) {
            return [];
        }
        const today = moment(
            moment().subtract(9, 'd').format('YYYY-MM-DD 00:00:00')
        ).valueOf();
        const result = dailyCallPerformance.dailyPerformance
            .filter((f) => f.day > today)
            .map((p) => ({
                performance: p.performance,
                reportDate: p.day,
            }));

        return result;
    }
);

export const getCallVolumeChartState = createSelector(
    [getPerformanceSummaryState],
    (summary) => {
        const { dailyCallPerformance } = summary;

        if (!dailyCallPerformance) {
            return [];
        }
        const result = dailyCallPerformance.dailyPerformance.map((p) => ({
            callVolume: p.callVolume,
            callDuration: p.totalDurationSec,
            reportDate: p.day,
        }));

        return result;
    }
);

export const getCallPerformanceSummaryChartState = createSelector(
    [getCallPerformanceState],
    (callPerformance) => {
        const { summary } = callPerformance;

        if (!summary) {
            return [];
        }
        return summary;
    }
);
export const getCallPerformance_AP_State = createSelector(
    [getCallPerformanceState],
    (callPerformance) => {
        const { aps } = callPerformance;

        if (!aps) {
            return [];
        }
        return aps;
    }
);
export const getCallPerformance_DeviceState = createSelector(
    [getCallPerformanceState],
    (callPerformance) => {
        const { devices } = callPerformance;

        if (!devices) {
            return [];
        }
        return devices;
    }
);

export const getPerformance_DroppedCallSummaryState = createSelector(
    [getPerformance_DroppedCallState],
    (performance) => {
        const { summary } = performance;

        if (!summary) {
            return [];
        }
        return summary;
    }
);
export const getPerformance_DroppedCall_AP_State = (state) =>
    getPerformance_DroppedCallState(state).aps || [];
export const getPerformance_DroppedCall_DeviceState = (state) =>
    getPerformance_DroppedCallState(state).devices || [];

export const getPerformance_LongHandOffSummaryState = (state) =>
    getPerformance_LongHandoffState(state).summary || [];
export const getPerformance_LongHandOff_DeviceState = (state) =>
    getPerformance_LongHandoffState(state).devices || [];
export const getPerformance_LongHandOff_AP_State = (state) =>
    getPerformance_LongHandoffState(state).aps || [];

export const getPerformance_PacketLossSummaryState = (state) =>
    getPerformance_PacketLossState(state).summary || [];
export const getPerformance_PacketLoss_DeviceState = (state) =>
    getPerformance_PacketLossState(state).devices || [];
export const getPerformance_PacketLoss_AP_State = (state) =>
    getPerformance_PacketLossState(state).aps || [];

export const getPerformance_LowSignalStrengthSummaryState = (state) =>
    getPerformance_LowSignalStrengthState(state).summary || [];
export const getPerformance_LowSignalStrength_DeviceState = (state) =>
    getPerformance_LowSignalStrengthState(state).devices || [];
export const getPerformance_LowSignalStrength_AP_State = (state) =>
    getPerformance_LowSignalStrengthState(state).aps || [];

// administrator
export const getOnboardingLicenseKeyState = (state) =>
    getOnboardingState(state).licenseKey;
export const getOnboardingLicenseState = (state) =>
    getOnboardingState(state).license;
export const getOnboardingSummaryInfotate = (state) =>
    getOnboardingState(state).summaryInfo;
export const getOnboardingContinueCompleteState = (state) =>
    getOnboardingState(state).continueComplete;
export const getOnboardingLocationsState = (state) =>
    getOnboardingState(state).locations;
export const getOnboardingUrlsState = (state) => getOnboardingState(state).urls;

//license
export const getLicenseSummaryState = (state) =>
    getLicensesState(state).summary;

//organizations
export const getOrganizationTableState = (state) =>
    getOrganizationsState(state).table;

//getAdminUserState
export const getAdminUserTableState = (state) => getAdminUserState(state).table;

//devices / batteries
export const getDeviceOrBatteryActiveDeviesState = (state) =>
    getDeviceOrBatteryState(state).activeDevices;
export const getDeviceOrBatteryLocationSpareState = (state) =>
    getDeviceOrBatteryState(state).locationSpares;

//spe user
export const getSpectralinkAdminUserTableState = (state) =>
    getUserState(state).userTable;
export const getSpectralinkAlertThresholdTableState = (state) =>
    getAppState(state).alertThreshold;
