import store from '../redux/store';
import * as accountAction from '../redux/actions/accountAction';
import { AppConstants } from '../constants/constants';
import moment from 'moment-timezone';

export const setTitle = (title) => {
    store.dispatch({
        type: AppConstants.SET_TITLE,
        data: title,
    });
};

export const logout = () => {
    store.dispatch(accountAction.logout());
};

export const checkPermissions = (code) => {
    const permissions = store.getState().account.current.permissions;
    if (permissions.length > 0) {
        // debugger
    }
    return permissions.includes(code);
};

export function getUserTimeZoneTime(time) {
    const timezone = store
        .getState()
        .account.current.timezone.replace(/ /g, '_');

    let userTime = null;
    if (typeof time === 'object') {
        // if current time is DST
        userTime = time.clone().tz(timezone);
    } else {
        userTime = moment(time).tz(timezone);
    }

    // DST
    const currentIsDST = moment.tz(moment(), timezone).isDST();
    if (currentIsDST && !userTime.isDST()) {
        if (userTime.format('HH:mm:ss') === '23:00:00') {
            userTime.add(1, 'h');
        }
    }

    return userTime;
}

export const getCurrentOrganizationAvailableLocations = (code) => {
    const state = store.getState();
    const accountLocations = state.common.accountLocations;
    if (!accountLocations) {
        return [];
    }
    if (state.app.location) {
        return [{
            value: state.app.location
        }]
    }
    const organizationId = state.app.organization;

    return accountLocations.filter((f) => f.organizationId === organizationId);
};
export const getNotificationTimeRange = () => {
    return store.getState().app.notificationTimeRange;
};
