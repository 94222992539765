import { NotificationConstants } from "../../constants/constants";

const initState = {
    table: {
        filter: {},
    },
    emailNotificationDetail:{},
    smsNotificationDetail:{}
}

const notificationReducer = (state = initState, action) => {
    switch (action.type) {
        case NotificationConstants.LOAD_NOTIFICATION_TABLE: {
            return {
                ...state,
                table: action.data
            }
        }
          case NotificationConstants.GET_EMAIL_NOTIFICATION_DETAIL: {
            return {
                ...state,
                emailNotificationDetail: action.payload
            }
        }
            case NotificationConstants.GET_SMS_NOTIFICATION_DETAIL: {
                return {
                    ...state,
                    smsNotificationDetail: action.payload
                }
        }
        default:
            return state;
    }
}
export default notificationReducer;