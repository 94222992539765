import React, { Component } from 'react';
// import FullLayout from 'layout/full-layout/full-layout';
import { Button, Modal, message } from 'antd';
import { FormContainer, FormInput, FormItemContainer } from 'module/form';
import './add-license.scss';
import './authenticate-license2.scss';
import { onboarding, licenses } from 'api';
import moment from 'moment';
import { decodeAmiePackage } from 'utils';
import { connect } from 'react-redux';
import {
    getUserDateFormat,
    getCurrentOrganizationState,
} from 'redux/selectors';
import { FormattedMessage, injectIntl } from 'react-intl';
class AddLicense extends Component {
    state = {
        license: null,
        errorMessageModalVisible: false,
        errorMessage: '',
    };

    submit = async (values) => {
        try {
            const license = await onboarding.authenticationLicense(
                values.licenseKey,
                this.props.currentOrganization,
            );

            if (license) {
                switch (license.SKUCategoryChange) {
                    case 'Adv-Ess':
                        Modal.confirm({
                            width: 600,
                            title: '',
                            okText: 'Proceed',
                            content: `You are about to install an AMIE Essentials license. 
                            Since it is not possible to mix AMIE licenses, 
                            if you proceed and install this license, then your existing AMIE Advanced licenses will be deleted. 
                            If you are not ready to delete your AMIE Advanced licenses (e.g., it has not yet expired), 
                            then please do not proceed and wait until you are ready. 
                            Are you sure you want to proceed?                            `,
                            onOk: () => {
                                this.setState({
                                    license,
                                    key: values.licenseKey,
                                });
                            },
                        });
                        // if (
                        //     license.StartDateofNewLicense === 'TodayorPast' &&
                        //     license.AllCurrentLicensesExpired
                        // ) {
                        //     this.setState({
                        //         license,
                        //         key: values.licenseKey,
                        //     });
                        // } else if (
                        //     license.StartDateofNewLicense === 'TodayorPast' &&
                        //     !license.AllCurrentLicensesExpired
                        // ) {
                        //     Modal.confirm({
                        //         width: 600,
                        //         title: '',
                        //         okText: 'Proceed',
                        //         content: `Warning: By installing this AMIE Essential license you will downgrade your AMIE access from Advanced to Essential.
                        //         We recommend waiting to install this license until the AMIE Advanced license's expiry date to avoid invalidating the AMIE Advanced license.`,
                        //         onOk: () => {
                        //             this.setState({
                        //                 license,
                        //                 key: values.licenseKey,
                        //             });
                        //         },
                        //     });
                        // } else if (
                        //     license.StartDateofNewLicense === 'Future' &&
                        //     license.AllCurrentLicensesExpired
                        // ) {
                        //     Modal.info({
                        //         width: 600,
                        //         title: '',
                        //         okText: 'Ok',
                        //         content: `The AMIE license you are installing has a start date in the future and will be marked as pending and devices will not connect to AMIE until the start date of this license.`,
                        //         onOk: () => {
                        //             this.setState({
                        //                 license,
                        //                 key: values.licenseKey,
                        //             });
                        //         },
                        //     });
                        // } else if (
                        //     license.StartDateofNewLicense === 'Future' &&
                        //     !license.AllCurrentLicensesExpired
                        // ) {
                        //     Modal.confirm({
                        //         width: 600,
                        //         title: '',
                        //         okText: 'Proceed',
                        //         content: `You are about to install an AMIE Essential license with a start date in the future.
                        //         Since it is not possible to mix AMIE Advanced and Essential licenses,
                        //         if you proceed you will Continue to have Advanced access until the start date of the AMIE Essential license and on that date the AMIE Advanced license will be invalidated.`,
                        //         onOk: () => {
                        //             this.setState({
                        //                 license,
                        //                 key: values.licenseKey,
                        //             });
                        //         },
                        //     });
                        // }
                        break;
                    case 'Ess-Adv':
                        Modal.confirm({
                            width: 600,
                            title: '',
                            okText: 'Proceed',
                            content: `You are about to install an AMIE Advanced license. 
                            Since it is not possible to mix AMIE licenses, 
                            if you proceed and install this license, then your existing AMIE Essentials licenses will be deleted. 
                            If you are not ready to delete your AMIE Essentials licenses (e.g., it has not yet expired), 
                            then please do not proceed and wait until you are ready. 
                            Are you sure you want to proceed?                            `,
                            onOk: () => {
                                this.setState({
                                    license,
                                    key: values.licenseKey,
                                });
                            },
                        });
                        // if (
                        //     license.StartDateofNewLicense === 'TodayorPast' &&
                        //     license.AllCurrentLicensesExpired
                        // ) {
                        //     this.setState({
                        //         license,
                        //         key: values.licenseKey,
                        //     });
                        // } else if (
                        //     license.StartDateofNewLicense === 'TodayorPast' &&
                        //     !license.AllCurrentLicensesExpired
                        // ) {
                        //     Modal.confirm({
                        //         width: 600,
                        //         title: '',
                        //         okText: 'Proceed',
                        //         content: `You are about to install an AMIE Advanced license.
                        //         Since it is not possible to mix AMIE licenses,
                        //         if you proceed and install this license your existing AMIE Essential license will become invalidated.
                        //         Are you sure you want to proceed?`,
                        //         onOk: () => {
                        //             this.setState({
                        //                 license,
                        //                 key: values.licenseKey,
                        //             });
                        //         },
                        //     });
                        // } else if (
                        //     license.StartDateofNewLicense === 'Future' &&
                        //     license.AllCurrentLicensesExpired
                        // ) {
                        //     Modal.info({
                        //         width: 600,
                        //         title: '',
                        //         okText: 'Ok',
                        //         content: `The AMIE license you are installing has a start date in the future
                        //         and will be marked as pending and devices will
                        //         not connect to AMIE until the start date of this license. `,
                        //         onOk: () => {
                        //             this.setState({
                        //                 license,
                        //                 key: values.licenseKey,
                        //             });
                        //         },
                        //     });
                        // } else if (
                        //     license.StartDateofNewLicense === 'Future' &&
                        //     !license.AllCurrentLicensesExpired
                        // ) {
                        //     Modal.confirm({
                        //         width: 600,
                        //         title: '',
                        //         okText: 'Proceed',
                        //         content: `You are about to install an AMIE Advanced license with a start date in the future.
                        //         Since it is not possible to mix AMIE Advanced and Essential licenses,
                        //         if you proceed you will Continue to have Essential access until the start date of
                        //         the AMIE Advanced license and on that date the AMIE Essential license will be invalidated.`,
                        //         onOk: () => {
                        //             this.setState({
                        //                 license,
                        //                 key: values.licenseKey,
                        //             });
                        //         },
                        //     });
                        // }
                        break;
                    case 'Ess-Ess':
                        Modal.confirm({
                            width: 600,
                            title: '',
                            okText: 'Proceed',
                            content: `Do you want to proceed with installing the new AMIE Essentials License?`,
                            onOk: () => {
                                this.setState({
                                    license,
                                    key: values.licenseKey,
                                });
                            },
                        });
                        break;
                    case 'Adv-Adv':
                        Modal.confirm({
                            width: 600,
                            title: '',
                            okText: 'Proceed',
                            content: `Do you want to proceed with installing the new AMIE Advanced License?`,
                            onOk: () => {
                                this.setState({
                                    license,
                                    key: values.licenseKey,
                                });
                            },
                        });
                        break;
                    default:
                        this.setState({
                            license,
                            key: values.licenseKey,
                        });
                        // if (license.hasOtherType) {
                        //     Modal.warn({
                        //         title: '',
                        //         content:
                        //             'The old license(s) will be deleted upon proceeding',
                        //     });
                        // }
                        break;
                }
            }
        } catch (e) {
            this.setState({
                errorMessage: e.message,
                errorMessageModalVisible: true,
            });
        }
    };

    submitLicense = async () => {
        try {
            await licenses.addLicense(this.state.key);
            // this.props.history.replace("/administration/licenses");
            this.props.reload();
            this.props.closeLicenseAddModel();
        } catch (e) {
            message.error(e.message);
        }
    };

    setErrorMessageModalVisible = (errorMessageModalVisible) => {
        this.setState({ errorMessageModalVisible });
    };

    getForm = () => {
        return (
            <div className="authentication-license2">
                <p>
                    <FormattedMessage
                        id="licenses.label.add_license.text1"
                        defaultMessage="Please enter the additional License Key details that you
                    received via email below and then click SUBMIT"
                    />
                </p>

                <FormContainer
                    layout="horizontal"
                    className="license-key-form"
                    onSubmit={this.submit}
                >
                    <FormInput
                        name="licenseKey"
                        label={
                            <FormattedMessage
                                id="licenses.label.add_license.label.key"
                                defaultMessage="License Key"
                            />
                        }
                        colon={false}
                        maxLength={1000}
                    />

                    <Button htmlType="submit" type="primary" id='btn_submit_license'>
                        <FormattedMessage
                            id="licenses.label.add_license.button.submit"
                            defaultMessage="SUBMIT"
                        />
                    </Button>
                </FormContainer>

                <br />
                {/* <p style={{ maxWidth: 1000 }}>
                NOTE - if you are looking to UPDATE/RENEW an existing License - please select that License from the Listing and follow the UPDATE LICENSE flow
            </p> */}
            </div>
        );
    };

    render() {
        const { license, errorMessage, errorMessageModalVisible } = this.state;
        return (
            <>
                <Modal
                    title={
                        <FormattedMessage
                            id="licenses.label.add_license.modal.title"
                            defaultMessage="Add License"
                        />
                    }
                    width={900}
                    visible={this.props.licenseAddVisible}
                    destroyOnClose={true}
                    className="admin-create-license"
                    footer={null}
                    onCancel={this.props.closeLicenseAddModel}
                    maskClosable={false}
                >
                    {license ? (
                        <>
                            <p>
                                {
                                    <FormattedMessage
                                        id="licenses.label.add_license.text2"
                                        defaultMessage="Please review the License details below and
                                click SUBMIT to have this License loaded to the
                                system. You will then be able to review the
                                License details and determine where this License
                                should be applied"
                                    />
                                }
                            </p>

                            <p>
                                <FormattedMessage
                                    id="licenses.label.add_license.text3"
                                    defaultMessage="Here is what we have decoded for your License"
                                />
                            </p>

                            <FormContainer
                                onSubmit={this.submitLicense}
                                className="c horizontal"
                            >
                                <hr />
                                <FormItemContainer
                                    label={
                                        <FormattedMessage
                                            id="licenses.label.account_id"
                                            defaultMessage="Account ID"
                                        />
                                    }
                                >
                                    {license.companyNumber}
                                </FormItemContainer>

                                <FormItemContainer
                                    label={
                                        <FormattedMessage
                                            id="licenses.label.account_name"
                                            defaultMessage="Account Name"
                                        />
                                    }
                                >
                                    {license.companyName}
                                </FormItemContainer>
                                <FormItemContainer
                                    label={
                                        <FormattedMessage
                                            id="licenses.label.license_start"
                                            defaultMessage="License Start"
                                        />
                                    }
                                >
                                    {moment([
                                        license.fromDate.year,
                                        license.fromDate.month - 1,
                                        license.fromDate.day,
                                    ]).format(this.props.dateFormat)}
                                </FormItemContainer>
                                <FormItemContainer
                                    label={
                                        <FormattedMessage
                                            id="licenses.label.license_end"
                                            defaultMessage="License End"
                                        />
                                    }
                                >
                                    {moment([
                                        license.toDate.year,
                                        license.toDate.month - 1,
                                        license.toDate.day,
                                    ]).format(this.props.dateFormat)}
                                </FormItemContainer>
                                <FormItemContainer
                                    label={
                                        <FormattedMessage
                                            id="licenses.label.amie_package"
                                            defaultMessage="AMiE Package"
                                        />
                                    }
                                >
                                    {decodeAmiePackage(license.licenseType)}
                                </FormItemContainer>
                                <FormItemContainer
                                    label={
                                        <FormattedMessage
                                            id="licenses.label.number_of_devices"
                                            defaultMessage="Number of Devices"
                                        />
                                    }
                                >
                                    {license.numberOfDevices}
                                </FormItemContainer>

                                <hr />

                                <p>
                                    <FormattedMessage
                                        id="licenses.label.add_license.text3"
                                        defaultMessage="Please review and click SUBMIT if correct.
                                    The system will load the License against
                                    your Organization and then enable the Wizard
                                    flow to get the License enabled against your
                                    Locations/Devices"
                                    />
                                </p>

                                <Button htmlType="submit" type="primary">
                                    <FormattedMessage
                                        id="licenses.label.add_license.button.submit"
                                        defaultMessage="SUBMIT"
                                    />
                                </Button>
                            </FormContainer>
                        </>
                    ) : (
                        this.getForm()
                    )}
                </Modal>
                <Modal
                    visible={errorMessageModalVisible}
                    title={null}
                    footer={null}
                    width={600}
                    onCancel={() => this.setErrorMessageModalVisible(false)}
                >
                    <div className="txt-dark">
                        An issue has occurred during the loading of your License
                        Key. Please contact Spectralink Customer Support and
                        report the error code below.
                    </div>
                    <br />
                    <table className="border-table txt-dark">
                        <tbody>
                            <tr className="table-group">
                                <td colSpan={4}> North America</td>
                            </tr>
                            <tr>
                                <td>United States and Canada</td>
                                <td>Tel: 1-800-775-5330</td>
                                <td></td>
                                <td>English</td>
                            </tr>
                            <tr>
                                <td>Mexico Tech Support</td>
                                <td>Tel: +001-800-775-5330</td>
                                <td></td>
                                <td>English</td>
                            </tr>
                            <tr className="table-group">
                                <td colSpan={4}>
                                    Europe, Middle East, and Atrica (EMEA)
                                </td>
                            </tr>
                            <tr>
                                <td>France Tech Support</td>
                                <td>Tel: +33 176774541</td>
                                <td></td>
                                <td>Danish + English</td>
                            </tr>
                            <tr>
                                <td>Germany</td>
                                <td>Tel: 0800588900</td>
                                <td></td>
                                <td>Danish + English</td>
                            </tr>
                            <tr>
                                <td>Rest of EMEA </td>
                                <td>Tel: +45 76 281 281</td>
                                <td></td>
                                <td>Danish + English</td>
                            </tr>
                            <tr className="table-group">
                                <td colSpan={4}>Asia Pacific</td>
                            </tr>
                            <tr>
                                <td>Technical Support </td>
                                <td>Tel: +61-2-90370834</td>
                                <td></td>
                                <td>English</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <div className="text-center">
                        <span className="txt-orange">ERROR CODE</span>:{' '}
                        <span className="txt-dark bold">
                            {/*XX-XXXX-X*/} {errorMessage}
                        </span>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        dateFormat: getUserDateFormat(state),
        currentOrganization: getCurrentOrganizationState(state),
    };
}

export default connect(mapStateToProps)(AddLicense);
