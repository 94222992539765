import { basicConstants } from "../../constants/constants";

const initState = {
    dataDictionary: {
        data: [],
        filter: {},
        types: [],
    }
}
const basicReducer = (state = initState, action) => {
    switch (action.type) {
        case basicConstants.DATA_DICTIONARY: {
            return {
                ...state,
                dataDictionary: {
                    ...state.dataDictionary,
                    ...action.data
                }
            }
        }
        case basicConstants.LOAD_DATA_DICTIONARY_TYPES: {
            return {
                ...state,
                dataDictionary: {
                    ...state.dataDictionary,
                    types: action.data
                }
            }
        }
        default:
            return state;
    }
}
export default basicReducer;