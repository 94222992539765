import { Modal } from "antd";

const popup = {
    success: (title, msg) => Modal.success(
        {
            title: title,
            content: msg,
        }),
    error: (title, msg) => Modal.error(
        {
            title: title,
            content: msg,
        }),

}

export default popup;