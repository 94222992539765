import { administrationConstants } from 'constants/constants';

const initState = {
    activeDevices: {
        location: ''
    },
    locationSpares: {
        location: ''
    }
};

const devicesOrButteriesReducer = (state = initState, action) => {
    switch (action.type) {
        case administrationConstants.SET_ACTIVE_DEVICES_LOCATION: {
            return {
                ...state,
                activeDevices: {
                    ...state.activeDevices,
                    location: action.payload
                }
            };
        }
        case administrationConstants.SET_LOCATION_SPARES_LOCATION: {
            return {
                ...state,
                locationSpares: {
                    ...state.locationSpares,
                    location: action.payload
                }
            };
        }
        default:
            return state;
    }
};
export default devicesOrButteriesReducer;
