import React, { PureComponent } from 'react';
import { Radio, Form } from 'antd';
import { FormContext } from '../context';

export default class FormRadioButtonGroup extends PureComponent {
    static contextType = FormContext;

    render() {
        const {
            options,
            value,
            name,
            rules,
            label,
            onChange,
            className,
            colon,
            disabled
        } = this.props;
        const { form } = this.context;
        return (
            <Form.Item
                label={label}
                className="form-radio-button-group"
                colon={colon === false ? false : true}
            >
                {form.getFieldDecorator(name, {
                    rules: rules,
                    initialValue: value
                })(
                    <Radio.Group
                        name={name}
                        disabled={disabled}
                        className={className || ''}
                        onChange={onChange}
                    >
                        {options &&
                            options.map(option => (
                                <Radio.Button
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.text}
                                </Radio.Button>
                            ))}
                    </Radio.Group>
                )}
            </Form.Item>
        );
    }
}
