import { alertConstants } from "../../constants/constants";

const initState = {
    table: {
        filter: {},
    },
    rules: {},
    notifications: [],
    ruleOperationsDefinitions: [],
    criteriaDefinitions: [],
}

const siteReducer = (state = initState, action) => {
    switch (action.type) {
        case alertConstants.LOAD_ALERT_TABLE: {
            return {
                ...state,
                table: action.data
            }
        }
        case alertConstants.GET_ALERT_RULES: {
            return {
                ...state,
                rules: action.data,
            };
        }
        case alertConstants.GET_NOTIFICATIONS: {
            return {
                ...state,
                notifications: action.payload,
            };
        }
        case alertConstants.GET_RULE_OPERATIONS_DEFINITIONS: {
            return {
                ...state,
                ruleOperationsDefinitions: action.payload,
            };
        }
        case alertConstants.GET_CRITERIA_DEFINITIONS: {
            return {
                ...state,
                criteriaDefinitions: action.payload,
            };
        }
        default:
            return state;
    }
}
export default siteReducer;