import { batteryConstants } from "../../constants/constants";

const initState = {
    total: 0,
    batteriesLoading: true,
    summary: {
        batteryStatus: {},
        batteryInUse: {},
        topApps: [],
    },
    table: {
        filter: {},
    },
    detail: {}
}

const batteryReducer = (state = initState, action) => {
    switch (action.type) {
        case batteryConstants.LOAD_BATTERY_TABLE: {
            return {
                ...state,
                table: action.data
            }
        }
        case batteryConstants.LOAD_BATTERY_TOTAL: {
            return {
                ...state,
                total: action.payload
            }
        }
        case batteryConstants.GET_BATTERIES_SUMMARY_DATA: {
            let total = 0
            for (const timeRange in action.payload.batteryStatus) {
                total += action.payload.batteryStatus[timeRange]
            }
            return {
                ...state,
                summary: {
                    ...state.summary,
                    ...action.payload
                },
                total,
                batteriesLoading: false
            }
        }
        case batteryConstants.GET_BATTERIES_STATUS_SUMMARY: {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    batteryStatus: action.payload
                }
            }
        }
        case batteryConstants.GET_BATTERIES_CHECK_IN_SUMMARY: {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    batteryCheckIn: action.payload
                }
            }
        }
        case batteryConstants.GET_TOP_APPS_SUMMARY_DATA: {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    topApps: action.payload
                }
            }
        }
        case batteryConstants.GET_BATTERY_DETAIL: {
            return {
                ...state,
                detail: action.payload
            }
        }
        case batteryConstants.CLEAR_BATTERY_DETAIL: {
            return {
                ...state,
                detail: {}
            }
        }
        default:
            return state;
    }
}
export default batteryReducer;