export default {
    CREATE_ACCOUNT_ENDPOINT: process.env.REACT_APP_CREATE_ACCOUNT_ENDPOINT,
    CURRENT_ENDPOINT: process.env.REACT_APP_CURRENT_ENDPOINT,
    S3_RESOURCE_ENDPOINT: process.env.REACT_APP_S3_RESOURCE_ENDPOINT,
    REACT_APP_API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
    NODE_ENV: process.env.NODE_ENV,
    show_language: (process.env.REACT_APP_SHOW_LANGUAGE === 'true'),
    APILOGGING: (process.env.REACT_APP_APILOGGING === 'true'),
    aws: {
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,

        sesSender: process.env.REACT_APP_AWS_SES_SENDER,

        s3Bucket: process.env.AWS_S3_Bucket,
        s3ArchivesBucket: process.env.AWS_S3_ARCHIVES_BUCKET,

        cognitoUserRegion: process.env.REACT_APP_AWS_COGNITO_USER_REGION,
        cognitoUserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
        cognitoUserClientId: process.env.REACT_APP_AWS_COGNITO_USER_CLIENT_ID,
    },
};
