import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import './full-layout.scss';

export default class FullLayout extends PureComponent {
    getHeader = () => {
        const { header } = this.props;

        if (header === null) {
            return <></>;
        }

        return header ? (
            header
        ) : (
            <header>
                <Link to="/">
                    <img className="logo" src={logo} alt="logo" />
                    <span className="brand-text">AMiE</span>
                </Link>
            </header>
        );
    };

    render() {
        const { children, className } = this.props;
        return (
            <div className={`full-layout ${className}`}>
                {this.getHeader()}
                <main>{children}</main>
            </div>
        );
    }
}
