import { organizationConstant } from "../../constants/constants";

const initState = {
    table: {
        filter: {},
    } 
}
const organizationsReducer = (state = initState, action) => {
    switch (action.type) {
        case organizationConstant.LOAD_ORGANIZATION_TABLE: {
            return {
                ...state,
                table: action.data
            }
        }
        default:
            return state;
    }
}
export default organizationsReducer;