import React, { useEffect, memo } from 'react';
//import localENUS from 'locales/en-US';

function ENUS(props) {
    const { addLocal, languageTexts } = props;
    useEffect(() => {
        addLocal(languageTexts);
    }, [addLocal, languageTexts]);

    return <></>;
}
export default memo(ENUS, () => true);
