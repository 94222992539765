// Store common data such as drop-down list data

import {
    devices,
    alerts,
    locations,
    setting,
    organizations,
    account,
    awsRegions,
    timeZones,
    addresses,
    ssidsAPI
} from '../../api';

import {
    commonConstants,
    settingConstants
    //  AccountConstants
} from '../../constants/constants';

const getDeviceModels = id => {
    return async dispatch => {
        const data = await devices.getModels(id);
        dispatch({
            type: commonConstants.GET_COMMON_DEVICE_MODELS,
            payload: data
        });
    };
};
const getDeviceVersityVersionss = id => {
    return async dispatch => {
        const data = await devices.getVersityVersions(id);
        dispatch({
            type: commonConstants.GET_COMMON_DEVICE_VERSITY_VERSIONS,
            payload: data
        });
    };
};

const getAlertStatus = id => {
    return async dispatch => {
        const data = await alerts.getStatus();
        dispatch({
            type: commonConstants.GET_COMMON_ALERT_STATUS,
            payload: data
        });
    };
};
const getAlertTypes = id => {
    return async dispatch => {
        const data = await alerts.getTypes();
        dispatch({
            type: commonConstants.GET_COMMON_ALERT_TYPES,
            payload: data
        });
    };
};

const getLocations = id => {
    return async dispatch => {
        const data = await locations.getLocationDropdownList(id);
        dispatch({ type: commonConstants.GET_COMMON_LOCATIONS, payload: data });
    };
};

const getOrganizations = () => {
    return async dispatch => {
        const data = await organizations.getOptions();
        dispatch({ type: commonConstants.GET_ORGANIZATIONS, payload: data });
    };
};

const getTenantsAlertCount = () => {
    return async dispatch => {
        const data = await alerts.getTenantsAlertCount();
        dispatch({
            type: commonConstants.GET_ALERT_COUNT_BY_TENANT,
            payload: data
        });
        return data;
    };
};
const getSettingsList = id => {
    return async dispatch => {
        const data = await setting.getAll(id);
        if (data.result) {
            dispatch({
                type: settingConstants.LOAD_SETTING_TABLE,
                payload: data.settings
            });
        }
    };
};
const getSSIDSByTenantId=(tenantid)=>{
    return async dispatch=>{
        const data=await ssidsAPI.getAll(tenantid);
        if(data.result){
            dispatch({
                type: settingConstants.SSIDS,
                payload:data.ssids
            })
        }
    }
}
const getRolegetOptions = () => {
    return async dispatch => {
        const data = await account.role.getOptions();
        dispatch({ type: commonConstants.GET_ROLE_OPTIONS, payload: data });
    };
};

const getLocationOptions = () => {
    return async dispatch => {
        const data = await locations.getOptions();
        dispatch({ type: commonConstants.GET_LOCATION_OPTIONS, payload: data });
    };
};

const getAWSRegionOptions = () => {
    return async dispatch => {
        const data = await awsRegions.getOptions();
        dispatch({
            type: commonConstants.GET_AWS_REGION_OPTIONS,
            payload: data
        });
    };
};
const getDeviceTags = () => {
    return async dispatch => {
        const data = await devices.getTags();
        dispatch({ type: commonConstants.GET_DEVICE_TAGS, payload: data });
    };
};
const getTimeZones = () => {
    return async dispatch => {
        const data = await timeZones.getOptions();
        dispatch({
            type: commonConstants.GET_TIME_ZONE_OPTIONS,
            payload: data
        });
    };
};

const getCountryOptions = () => {
    return async dispatch => {
        const data = await addresses.getCountryOptions();
        dispatch({ type: commonConstants.GET_COUNTRY_OPTIONS, payload: data });
    };
};

const getSubdivisionOptions = () => {
    return async dispatch => {
        const data = await addresses.getSubdivisionOptions();
        dispatch({
            type: commonConstants.GET_SUBDIVISION_OPTIONS,
            payload: data
        });
    };
};

const getAccountOptions = () => {
    return async dispatch => {
        const options = await account.getOptions();
        dispatch({
            type: commonConstants.GET_ACCOUNTS_OPTIONS,
            payload: options
        });
    };
};

const getAccountOrganizations = accountId => {
    return async dispatch => {
        const data = await organizations.getOptions(accountId);
        dispatch({
            type: commonConstants.GET_ACCOUNT_ORGANIZATIONS,
            payload: data,
            accountId
        });
    };
};

const getOrganizationLocationOptions = (accountId, organizationId) => {
    return async dispatch => {
        const data = await locations.getOptions(organizationId);
        dispatch({
            type: commonConstants.GET_ORGANIZATION_OPTIONS,
            payload: data,
            accountId,
            organizationId
        });
    };
};

export default {
    getDeviceModels,
    getDeviceVersityVersionss,
    getAlertStatus,
    getAlertTypes,
    getLocations,
    getOrganizations,
    getTenantsAlertCount,
    getSettingsList,
    getRolegetOptions,
    getLocationOptions,
    getAWSRegionOptions,
    getDeviceTags,
    getTimeZones,
    getCountryOptions,
    getSubdivisionOptions,

    getAccountOptions,
    getAccountOrganizations,
    getOrganizationLocationOptions,
    getSSIDSByTenantId
};
