import React, { PureComponent } from "react";
import { Checkbox, Form } from 'antd';
import { FormContext } from "../context";


export default class FormCheckBoxGroup extends PureComponent {
    static contextType = FormContext;

    render() {
        const { options, value, name, rules, label,  onChange,className, colon} = this.props;
        const { form } = this.context;
        return (
            <Form.Item label={label} className="form-checkboxgroup" colon={colon === false ? false : true}>
                {form.getFieldDecorator(name, {
                    rules: rules,
                    initialValue: value
                })(
                    <Checkbox.Group
                        label={label}
                        name={name}
                        className={className || ''}
                        onChange={onChange}
                        options={options}
                        defaultValue={value}
                    >

                    </Checkbox.Group>,
                )}
            </Form.Item>
        );
    }
}
