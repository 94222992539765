import { combineReducers } from 'redux';

import permissionReducer from './permissionReducer';
import devicesOrButteriesReducer from './devicesOrButteriesReducer';

const administrationReducer = combineReducers({
    permission: permissionReducer,
    devicesOrButteries: devicesOrButteriesReducer
});
export default administrationReducer;
