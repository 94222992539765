import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAccountPermissionState, getAppMenusState } from 'redux/selectors';
import { Modal } from 'antd';
import { globalConstants, permissionConstants } from 'constants/constants';

window.auth_alert = false;

class AuthRoute extends Component {
    checkPermission = (props) => {
        const { code, permissions } = this.props;

        if (!this.props.isLogin) {
            props.history.push('/');
            return false;
        }

        if (!code) {
            return true;
        }

        if (permissions.includes(code)) {
            return true;
        }

        if (!window.auth_alert) {
            window.auth_alert = true;

            if (code === permissionConstants.ORGANIZATIONS) {
                if (props.history.location.pathname !== '/') {
                    props.history.replace('/');
                }
                return;
            }
            Modal.error({
                title: globalConstants.UNAUTHORIZED,
                onOk: () => {
                    window.auth_alert = false;
                    if (props.history.location.pathname !== '/') {
                        props.history.push('/');
                    }
                },
            });
        }

        return false;
    };

    render() {
        const { path, exact, component: Com, setIFrame } = this.props;

        return (
            <>
                <Route
                    path={path}
                    exact={exact}
                    render={(props) => {
                        if (!this.checkPermission(props)) {
                            return <></>;
                        }
                        return <Com {...props} setIFrame={setIFrame} />;
                    }}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        permissions: getAccountPermissionState(state),
        menus: getAppMenusState(state),
        isLogin: state.account.isLogin,
    };
}
export default connect(mapStateToProps)(AuthRoute);
