import React, { PureComponent } from 'react';
import { Form, Checkbox } from 'antd';
import { FormContext } from "../context";

export default class FormCheckbox extends PureComponent {
    static contextType = FormContext;

    render() {
        const { name, label, checked, onClick, rules, id } = this.props;
        const { form } = this.context;

        return (<Form.Item className={`form-checkbox`} id={id || ("form_item_" + name)}>
            {form.getFieldDecorator(name, {
                valuePropName: 'checked',
                rules: rules,
                initialValue: checked,
            })(<Checkbox name={name} onClick={onClick}>{label}</Checkbox>)}
        </Form.Item>);
    }
}