const phoneValidator = (rule, value, callback) => {
    if (value && !/^[0-9]{10,11}$/.test(value)) {
        callback('Please enter the correct telephone number!');
    } else {
        callback();
    }
};

const required = msg => ({
    required: true,
    message: msg
});

const isEmail = msg => ({
    type: 'email',
    message: msg || 'Please enter the correct email!'
});

const phone = msg => ({
    validator: phoneValidator
});

export { required, isEmail, phone };
