export default {
    'users.title': 'x',
    'users.username': 'x',
    'user.owner': 'x',
    'users.name': 'x',
    'users.role': 'x',
    'users.phone': 'x',
    'users.last_login': 'x',
    'users.add': 'x',
    'users.add.modal_title': 'x',
    'users.edit.modal_title': 'x',
    'users.user_role': 'x',
    'users.location_name': 'x',
    'users.view_location_data': 'x',
    'users.reset_password': 'x',
    'users.tip.delete': 'x',
};
