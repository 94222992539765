import { commonConstants, settingConstants } from "../../constants/constants";

const initState = {
    deviceModels: [],
    versityVersions: [],
    alertTypes: [],
    alertStatus: [],
    locations: [],
    accountLocations: [],
    organizations: [],
    alertCount: [],
    setingsList: [],
    SSIDS:[],
    roles: [],
    awsRegions: [],
    deviceTags: [],
    timeZones: [],
    countries: [],
    subdivisions: [],

    accounts: [
        // {
        //     text: "",
        //     value: "",
        //     organizations: [
        //         {
        //             text: "",
        //             value: "",
        //             locations: [
        //                 {
        //                     text: "",
        //                     value: "",
        //                 }
        //             ]
        //         }
        //     ]
        // }
    ]
}

const commonReducer = (state = initState, action) => {
    switch (action.type) {

        case commonConstants.GET_COMMON_DEVICE_MODELS: {
            return {
                ...state,
                deviceModels: action.payload
            }
        }
        case commonConstants.GET_ROLE_OPTIONS: {
            return {
                ...state,
                roles: action.payload
            }
        }
        case commonConstants.GET_COMMON_DEVICE_VERSITY_VERSIONS: {
            return {
                ...state,
                versityVersions: action.payload
            }
        }
        case commonConstants.GET_COMMON_ALERT_TYPES: {
            return {
                ...state,
                alertTypes: action.payload
            }
        }
        case commonConstants.GET_COMMON_ALERT_STATUS: {
            return {
                ...state,
                alertStatus: action.payload
            }
        }
        case commonConstants.GET_COMMON_LOCATIONS: {
            return {
                ...state,
                locations: action.payload
            }
        }
        case commonConstants.GET_LOCATION_OPTIONS: {
            return {
                ...state,
                accountLocations: action.payload
            }
        }

        case commonConstants.GET_ORGANIZATIONS: {
            return {
                ...state,
                organizations: action.payload
            }
        }

        case commonConstants.GET_ALERT_COUNT_BY_TENANT: {
            return {
                ...state,
                alertCount: action.payload
            }
        }
        case settingConstants.LOAD_SETTING_TABLE: {
            return {
                ...state,
                setingsList: action.payload
            }
        }
        case settingConstants.SSIDS:{
            return {
                ...state,
                SSIDS:action.payload
            }
        }
        case commonConstants.GET_AWS_REGION_OPTIONS: {
            return {
                ...state,
                awsRegions: action.payload
            }
        }
        case commonConstants.GET_DEVICE_TAGS: {
            return {
                ...state,
                deviceTags: action.payload
            }
        }
        case commonConstants.GET_TIME_ZONE_OPTIONS: {
            return {
                ...state,
                timeZones: action.payload
            }
        }
        case commonConstants.GET_COUNTRY_OPTIONS: {
            return {
                ...state,
                countries: action.payload
            }
        }
        case commonConstants.GET_SUBDIVISION_OPTIONS: {
            return {
                ...state,
                subdivisions: action.payload
            }
        }

        case commonConstants.GET_ACCOUNTS_OPTIONS: {
            let accounts = action.payload;

            accounts.forEach(account => {
                const info = state.accounts.find(f => f.value === account.value);
                if (info) {
                    account.organizations = info.organizations;
                }
            });

            return {
                ...state,
                accounts: accounts
            }
        }
        case commonConstants.GET_ACCOUNT_ORGANIZATIONS: {
            let accounts = state.accounts.slice();

            let index = accounts.findIndex(f => f.value === action.accountId)

            if (index > -1) {
                accounts[index] = {
                    ...accounts[index],
                    organizations: action.payload
                }
            }
            return {
                ...state,
                accounts: accounts
            }
        }
        case commonConstants.GET_ORGANIZATION_OPTIONS: {
            let accounts = state.accounts.slice();
            let index = accounts.findIndex(f => f.value === action.accountId)

            if (index > -1) {
                const organizationIndex = accounts[index].organizations && accounts[index].organizations.findIndex(f => f.value === action.organizationId)
                if (organizationIndex > -1) {
                    accounts[index].organizations[organizationIndex] = {
                        ...accounts[index].organizations[organizationIndex],
                        locations: action.payload
                    }
                }
            }
            return {
                ...state,
                accounts: accounts
            }
        }
        default:
            return state;
    }
}

export default commonReducer;