export default {
    'dashboard.title': 'Dashboard',
    'dashboard.checked_in_last_24h': 'Checked-in {br} last 24h',
    'dashboard.previous': 'Previous',
    'dashboard.change': 'Change',
    'dashboard.trend': 'Trend',
    'alerts.title': 'Alerts',
    'alerts.alert_type': 'Alert Type',
    'alerts.alert_description': 'Alert Description',
    'alerts.days_open': 'Days Open',
    'alerts.serial': 'Serial',
    'alerts.battery_serial': 'Battery Serial',
    'alerts.device_serial': 'Device Serial',
    'alerts.edit_alert.modal_title': 'Update Alert Status',
    'alerts.alert_status': 'Alert Status',
    'alerts.alert_status.required': 'Alert Status is required',
    'alerts.comment': 'Comment',
    'alerts.comment.required': 'Comment is required',
    'devices.title': 'Devices',
    'devices.device_check_in': 'Device Check-In',
    'devices.device_utilization': 'Device Utilization',
    'devices.device_distribution': 'Device Distribution',
    'devices.spectralink_software': 'Spectralink Software',
    'devices.android_os': 'Android OS',
    'devices.phone_model': 'Phone Model',
    'devices.name': 'Name',
    'devices.versity_version': 'Versity Version',
    'devices.last_check_in': 'Last Check-in',
    'devices.model': 'Model',
    'devices.serial': 'Serial #',
    'devices.sip_ext': 'SIP Ext',
    'devices.tag_identifiers': 'Tag Identifiers',
    'devices.device_?_details': 'Device {name} Details',
    'devices.serial_no': 'Serial No.',
    'devices.primary_sip_extension': 'Primary SIP Extension',
    'devices.phone_number': 'Phone Number',
    'devices.last_location': 'Last Location',
    'devices.storage_utilization': 'Storage Utilization',
    'devices.ip_address': 'IP Address',
    'devices.current_last_battery': 'Current/Last Battery',
    'devices.device_activity': 'Device Activity',
    'devices.session_activity': 'Session Activity',
    'devices.device_activity_history': 'Device Activity History',
    'devices.message_to_device': 'Message To Device',
    'devices.device_call_history': 'Device Call History',
    'devices.cpu': 'CPU',
    'devices.memory': 'Memory',
    'devices.power': 'Power',
    'devices.usag_start': 'Usage Start',
    'devices.start_charge': 'Start Charge',
    'devices.usage_end': 'Usage End',
    'devices.end_charge': 'End Charge',
    'devices.last_network_name': 'Last Network Name',
    'devices.total_calls': 'Total Calls',
    'devices.total_call_time': 'Total Call Time',
    'devices.last_ap_bssid': 'Last AP Bssid',
    'devices.last_battery': 'Last Battery',
    'devices.retire': 'Retire',
    'devices.device_history': 'Device History',
    'devices.send_message': 'Send Message',
    'devices.send_message.verify_fail': 'Message cannot be empty and cannot be longer than 255 characters',
    'devices.reactivate': 'Reactivate',
    'devices.last_ap': 'Last AP',
    'devices.usage_start': 'Usage Start',
    'devices.send_message_placeholder': 'Good <<morning/afternoon/evening>>,Type here…',
    'devices.send_to_device': 'Send To Device',
    'devices.last_ssid': 'Last SSID',
    'devices.last_seen': 'Last Seen',
    'devices.device_serial_no': 'Device Serial No',
    'devices.last_known_location': 'Last Known Location',
    'devices.last_known_user': 'Last Known User',
    'devices.last_known_battery': 'Last Known Battery',
    'devices.device_in_use': 'Device In Use',
    'devices.send_message_tip': 'Message to display to any users that use this device',
    'devices.post_message': 'Post Message',
    'devices.send_message_fail': 'Send Message Fail',
    'devices.retire_fail': 'Retire Fail',
    'devices.retire_success': 'Retire Success',
    'devices.reactivate_success': 'Reactivate Success',
    'devices.used': 'Used',
    'devices.mAh': 'mAh',
    'devices.message_date_time': 'Message Date Time',
    'devices.message_type': 'Message Type',
    'devices.message_content': 'Message Content',
    'devices.sent_by': 'Sent By',
    'devices.status': 'Status',
    'devices.call_date_time': 'Call Date/Time',
    'devices.inbound_outbound': 'Inbound/Outbound',
    'devices.connected_aps': 'Connected APs',
    'devices.call_duration': 'Call Duration ({unit})',
    'devices.dropped_call': 'Dropped Call',
    'devices.score': 'Score',
    'devices.call_start_time': 'Call Start Time',
    'devices.sip_extension': 'SIP Extension',
    'devices.call_dropped': 'Call Dropped',
    'devices.call_mos_score': 'Call MOS Score',
    'devices.call_end_time': 'Call End Time',
    'devices.mos_score': 'Mos Score',
    'devices.start': 'Start',
    'devices.end': 'End',
    'devices.bssid': 'BSSID',
    'devices.ssid': 'SSID',
    'devices.call_segmen_mos': 'AP MOS Score',
    'devices.from_ap': 'From AP',
    'devices.to_ap': 'To AP',
    'devices.duration_on_this_ap': 'Duration On This AP',
    'devices.average_mos': 'Average MOS',
    'devices.handoff_duration': 'Handoff Duration',
    'devices.current_ap': 'Current AP',
    'devices.mos': 'MOS',
    'devices.jitter': 'Jitter',
    'devices.jitter_milliseconds': 'Jitter (milliseconds)',
    'devices.packet_loss': 'Packet Loss',
    'devices.packet_loss_number_of_packets': 'Packet Loss (number of packets)',
    'devices.devices_model': 'Devices Model',
    'devices.device_model': 'Device Model',
    'batteries.title': 'Battery',
    'batteries.battery_{name}_details': 'Battery {name} Details',
    'batteries.charge_level': 'Charge Level',
    'batteries.battery_model': 'Battery Model',
    'batteries.battery_serial': 'Battery Serial',
    'batteries.cycles': 'Cycles',
    'batteries.current_last_device': 'Current Last Device',
    'batteries.serial_no': 'Serial No.',
    'batteries.last_full_charge_date_time': 'Last Full Charge Date/Time',
    'batteries.battery_temp': 'Battery Temp',
    'batteries.last_full_charge_capacty': 'Last Full Charge Capacity',
    'batteries.battery_temperature': 'Battery Temperature',
    'batteries.battery_check_in': 'Battery Check-In',
    'batteries.top_apps': 'Top Apps',
    'batteries.secondary_battery_health': 'Secondary Battery Health',
    'batteries.battery_health': 'Battery Health',
    'batteries.remaining_capacity': 'Remaining Capacity',
    'batteries.cycle_count': 'Cycle Count',
    'batteries.session_activity': 'Session Activity',
    'batteries.battery_activity_history': 'Battery Activity History',
    'locations.ap_not_mapped_to_floorplan': 'AP not mapped to floorplan',
    'locations.no_last_location': 'No Last Location',
    'common.label.action': 'Action',
    'common.label.devices': 'Devices',
    'common.label.batteries': 'Batteries',
    'common.label.locations': 'Locations',
    'common.label.location': 'Location',
    'common.label.floor': 'floor',
    'common.label.battery_status': 'Battery Status',
    'common.label.device_status': 'Device Status',
    'common.label.status': 'Status',
    'common.label.created': 'Created',
    'common.label.modified': 'Modified',
    'common.label.summary': 'Summary',
    'common.label.details': 'details',
    'common.label.keyword': 'Keyword',
    'common.label.tag': 'Tag',
    'common.label.time_range': 'Time Range',
    'common.label.date': 'Date',
    'common.label.error': 'Error',
    'common.label.value': 'Value',
    'common.label.view': 'View',
    'common.label.yes': 'Yes',
    'common.label.no': 'No',
    'common.label.all': 'All',
    'common.label.device': 'Device',
    'common.label.seconds': 'Seconds',
    'common.status.good': 'Good',
    'common.status.warning': 'Warning',
    'common.status.replace': 'Replace',
    'common.status.active': 'Active',
    'common.status.out_of_contact': 'Out of contact',
    'common.status.spares': 'Spares',
    'common.button.actions': 'Actions',
    'common.button.search': 'Search',
    'common.button.cancel': 'Cancel',
    'common.button.previous': 'Previous',
    'common.button.next': 'Next',
    'common.button.confirm': 'Confirm',
    'common.button.save': 'Save',
    'common.button.delete': 'Delete',
    'common.button.apply': 'Apply',
    'common.button.export_to_csv': 'Export To CSV',
    'common.button.download': 'download',
    'common.button.browse': 'Browse',
    'common.button.edit': 'Edit',
    'common.button.assign': 'Assign',
    'common.button.review': 'Review',
    'common.button.unassign': 'Unassign',
    'common.button.add': 'Add',
    'common.button.upload': 'upload',
    'common.button.back': 'Back',
    'common.button.submit': 'Submit',
    'common.button.dismiss': 'Dismiss',
    'common.range.today': 'Today',
    'common.range.yesterday': 'Yesterday',
    'common.range.last_7_days': 'Last 7 days',
    'common.range.last_30_days': 'Last 30 days',
    'common.range.last_60_days': 'Last 60 days',
    'common.range.last_90_days': 'Last 90 days',
    "accounts.title": "Accounts",
    "accounts.column.super_user_email": "Super User Email",
    "accounts.column.account_name": "Account Name",
    "accounts.column.created": "Created",
    "accounts.column.status": "Status",
    "accounts.column.new_alerts": "New Alerts",
    "accounts.column.action": "Action",
    "accounts.column.delete.text": "Are you sure you want to delete this account?",
    "accounts.edit_title": "Edit Account",
    "accounts.label.account_name": "Account Name",
    "accounts.label.account_name.required": "Account Name is required.",
    "user.column.name": "Name",
    "user.column.username": "Username",
    "user.column.role": "Role",
    "user.column.phone_number": "Phone Number",
    "user.column.creation_date": "Creation Date",
    "user.column.last_login": "Last Login",
    "user.title": "System user",
    "user.delete.text": "Are you sure you want to delete this user?",
    "user.label.location_data_permissions": "Location Data Permissions",
    "user.label.location_name": "Location Name",
    "user.label.view_site_data": "View Site Data",
    "user.button.add": "ADD ANOTHER",
    "user.modal.add_title": "system-user Add",
    "user.label.name": "Name",
    "user.label.username": "Username",
    "user.label.phone": "Phone",
    "user.label.role": "User Role",
    "user.modal.edit_title": "Edit User - {a}",
    "user.button.reset_password": "Reset Password",
    "user.reset_password.text": "Please confirm that you want to reset this user's password",
    "user.button.unlock": "Unlock",
    "data_retention.tabs.data_archive": "Data Archive",
    "data_retention.title": "Data Retention",
    "data_retention.archived_data": "Archived Data",
    "data_retention.archived_data.text": " Archived data will be stored for 12 months. After 12 months archived data will be permanently deleted. If you wish to retain data, then please download archived prior to permanent deletion.",
    "data_retention.archived_data.text2": "Click the month below to download archived data",
    "data_retention.button.download_archive": "Download Archive",
    "data_retention.button.download_monthly_report": "Download Monthly Report",
    "data_retention.label.no_archived_data": " No archived data",
    "devices_batteries.tabs.active_devices": "Active Devices",
    "devices_batteries.tabs.device_retirement_history": "Device Retirement History",
    "devices_batteries.tabs.battery_retirement_history": "Battery Retirement History",
    "devices_batteries.tabs.location_spares": "Location Spares",
    "devices_batteries.title": "Devices/Batteries",
    "devices_batteries.label.location": "Location",
    "devices_batteries.button.bulk_load_spares": "ADD SPARE",
    "devices_batteries.label.serial": "Serial",
    "devices_batteries.label.model": "model",
    "devices_batteries.label.bulk_load_spares": "BULK LOAD SPARES",
    "devices_batteries.modal.bulk_load_spares": "Please select Spare List file to load",
    "devices_batteries.modal.bulk_load_spares.text": "NOTE the system expects a predetermined set of columns. Please {a} our template if you are not sure what that structure is.",
    "devices_batteries.column.serial": "Serial",
    "devices_batteries.column.model": "model",
    "devices_batteries.label.total_device_count": "Total Device Count",
    "devices_batteries.label.license_allocation": "License Allocation",
    "devices_batteries.label.devices_in_use": "Devices in Use",
    "devices_batteries.label.to_be_decommissioned": "To be Decommissioned",
    "devices_batteries.label.available_spares": "Available Spares",
    "devices_batteries.label.spares_list": "Spares List",
    "devices_batteries.button.remove": "REMOVE FROM LIST",
    "devices_batteries.label.battery_serial": "Battery Serial",
    "devices_batteries.label.battery_health": "Battery Health",
    "devices_batteries.label.remaining_capacity_mah": "Remaining Capacity (mAh)",
    "devices_batteries.label.cycles": "Cycles",
    "devices_batteries.label.degradation": "Degradation",
    "devices_batteries.label.status": "Status",
    "devices_batteries.label.location_name": "Location Name",
    "devices_batteries.label.batteries": "# Batteries",
    "devices_batteries.label.to_be_retired": "# to be Retired",
    "devices_batteries.label.average_battery_health": "Average Battery Health",
    "devices_batteries.label.devices": "# Devices",
    "devices_batteries.label.spares": "# Spares",
    "devices_batteries.label.device_type": "Device Type",
    "devices_batteries.label.remaining_capacity": "Remaining Capacity (mAh)",
    "devices_batteries.label.Status": "Status",
    "devices_batteries.label.device_serial": "Device Serial",
    "devices_batteries.label.device_name": "Device Name",
    "devices_batteries.label.device Model": "Device Model",
    "devices_batteries.label.sipextension": "'SIP Extension",
    "devices_batteries.label.last_location": "Last Location",
    "devices_batteries.label.product_type": "# Product Type",
    "devices_batteries.label.event_type": "Event",
    "devices_batteries.label.retired_by": "Retired By",
    "devices_batteries.label.auto_retired": "Auto Retired",
    "devices_batteries.label.date_retired": "Date Retired",
    "timezone.title": "Timezone",
    "timezone.label.date_format": "Date Format",
    "timezone.label.time_format": "Time Format",
    "timezone.label.save_time_settings": "Save Time Settings",
    "gateways.title": "Gateways",
    "gateways.tab.summary": "Summary",
    "gateways.tab.details": "Details",
    "gateways.configuration.organization_name": "Organization Name",
    "gateways.configuration.organization_name.required": "Please input Organization Name",
    "gateways.configuration.location_name": "Location Name",
    "gateways.configuration.location_name.required": "Please input Location Name",
    "gateways.configuration.gateway_size": "Gateway Size",
    "gateways.configuration.gateway_size.required": "Please select an gateway size",
    "gateways.configuration.self_signed_cert": "Self Signed Cert",
    "gateways.configuration.self_signed_cert.required": "Please select Self Signed Cert",
    "gateways.configuration.gateway_create_command": " Gateway Create {br} Command",
    "gateways.configuration.gateway_create_command.required": "Please input Gateway Create Command",
    "gateways.configuration.mqtts_certificate": "MQTTS Certificate",
    "gateways.configuration.ca_certificate": "CA Certificate",
    "gateways.configuration.ca_certificate.required": "Please input CA Certificate",
    "gateways.configuration.mqtts_certificate.required": "Please input MQTTS Certificate",
    "gateways.configuration.mqtts_private_key": "MQTTS Private Key",
    "gateways.configuration.mqtts_private_key.required": "Please input MQTTS Private Key",
    "common.button.close": "Close",
    "gateways.healths.column.cpu_util_percent": "Percent CPU Utilization",
    "gateways.healths.column.mem_util_percent": "Percent Memory Utilization",
    "gateways.healths.column.percent_free_disk_space": "Percent Free Disk Space",
    "gateways.healths.column.mqtt_connected_clients": "MQTT Connected Clients",
    "gateways.healths.column.mqtt_disconnected_clients": "MQTT Disconnected Clients",
    "gateways.healths.column.mqtt_dropped_messages": "MQTT Dropped Message Count",
    "gateways.healths.column.gateway_inbound_messages": "Gateway Inbound Message Count",
    "gateways.healths.column.gateway_to_cloud_messages": "Gateway to Cloud Message Count",
    "gateways.healths.column.gateway_queued_messages": "Gateway Queued Message Count",
    "gateways.healths.column.cloud_publish_message_error": "Cloud Publish Message Error Count",
    "gateways.healths.column.cloud_to_gateway_messages": "Cloud to Gateway Message Count",
    "gateways.healths.column.timestamp": "Timestamp",
    "gateways.details.column.gateway_name": "Gateway name",
    "gateways.details.column.organization_name": "Organization Name",
    "gateways.details.column.location_name": "Location Name",
    "gateways.details.column.number_of_devices": "Number of Devices",
    "gateways.details.column.gateway_health": "Gateway Health",
    "gateways.details.column.certificate_expiration_date": "Certificate Expiration Date",
    "gateways.summary.health": "Gateway Health",
    "gateways.summary.size": "Gateway Size",
    "gateways.summary.certificate_expiry": "Gateway Certificate Expiry",
    "organizations.title": "Organizations",
    "organizations.columns.deployment_region": "Deployment Region",
    "organizations.columns.description": "Organization Description",
    "common.columns.action": "Action",
    "organizations.delete.text": "Are you sure you want to delete this organization?",
    "organizations.button.add": "Add Organization",
    "organizations.label.name": "Organization Name",
    "organizations.label.name.required": "Organziation Name is required.",
    "organizations.label.deployment_region": "Deployment Region",
    "organizations.label.deployment_region.required": "Deployment Region is required.",
    "organizations.label.description": "Organization Description",
    "organizations.modal.title": "Edit Organization",
    "performance.title": "Performance",
    "performance.tab.call_performance_summary": "Call Performance Summary",
    "performance.tab.dropped_call_summary": "Dropped Call Summary",
    "performance.tab.long_handoff_summary": "Long Handoff Summary",
    "performance.tab.low_rssi_events_summary": "Low RSSI Events Summary",
    "performance.tab.packet_loss_summary": "Packet Loss Summary",
    "performance.call_performance_metrics_by_ap.columns.id": "AP BSSID",
    "performance.call_performance_metrics_by_ap.columns.avg_ap_mos": "Avg AP MOS",
    "performance.call_performance_metrics_by_ap.columns.low_mos_events": "Low MOS Events",
    "performance.call_performance_metrics_by_ap.columns.total_call_minutes": "Total Call Minutes",
    "performance.call_performance_metrics_by_ap.columns.low_mos_events_call_min": "Low MOS Events / Call Min",
    "performance.call_performance_metrics_by_ap.columns.grade": "Grade",
    "performance.call_performance_metrics_by_ap.columns.color": "Color",
    "performance.call_performance_metrics_by_device.columns.avg_ap_mos": "Avg Device MOS",
    "performance.call_performance_metrics_by_device.columns.low_mos_events": "Low MOS Events",
    "performance.call_performance_metrics_by_device.columns.total_call_minutes": "Total Call Minutes",
    "performance.call_performance_metrics_by_device.columns.low_mos_call_min_percentage": "Low MOS Events / Call Min",
    "performance.call_performance_metrics_by_ap.title": "Call Performance Metrics by AP",
    "performance.call_performance_metrics_by_device.title": "Call Performance Metrics by Device",
    "performance.dropped_calls_by_ap.columns.dropped_calls": "Total Dropped Calls",
    "performance.dropped_calls_by_ap.columns.total_calls": "total_calls",
    "performance.dropped_calls_by_ap.columns.dropped_call_rate": "Dropped Call Rate",
    "performance.dropped_calls_by_ap.columns.grade": "Grade",
    "performance.dropped_calls_by_device.columns.id": "Device ID",
    "performance.dropped_calls_by_device.columns.dropped_calls": "Total Dropped Calls",
    "performance.dropped_calls_by_device.columns.total_calls": "Total Calls",
    "performance.dropped_calls_by_device.columns.dropped_call_rate": "Dropped Call Rate",
    "performance.dropped_calls_by_ap.title": "Dropped Calls by AP",
    "performance.dropped_calls_by_device.title": "Dropped Calls by Device",
    "performance.dropped_missed_packets_by_ap.columns.packets_droppeds": "Dropped Packets",
    "performance.dropped_missed_packets_by_ap.columns.packets_droppeds_rate": "Dropped Packet Rate",
    "performance.dropped_missed_packets_by_ap.columns.packets_misseds": "Missed Packets",
    "performance.dropped_missed_packets_by_ap.columns.packets_misseds_rate": "Missed Packet Rate",
    "performance.dropped_missed_packets_by_ap.columns.grade": "Grade",
    "performance.dropped_missed_packets_by_device.column.id": "Device ID",
    "performance.dropped_missed_packets_by_device.column.packets_droppeds": "Dropped Packets",
    "performance.dropped_missed_packets_by_device.column.packets_droppeds_rate": "Dropped Packet Rate",
    "performance.dropped_missed_packets_by_device.column.packets_misseds": "Missed Packets",
    "performance.dropped_missed_packets_by_device.column.packets_misseds_rate": "Missed Packet Rate",
    "performance.dropped_missed_packets_by_ap.title": "Dropped/Missed Packets by AP",
    "performance.dropped_missed_packets_by_device.title": "Dropped/Missed Packets by Device",
    "performance.packet_loss_summary.chart.dropped_packet_rate": "Dropped Packet Rate",
    "performance.packet_loss_summary.chart.missed_packet_rate": "Missed Packet Rate",
    "performance.long_handoffs_by_ap.column.id": "AP BSSID",
    "performance.long_handoffs_by_ap.column.avg_handoff_time": "AVG Handoff Time (ms)",
    "performance.long_handoffs_by_ap.column.long_handoff": "Total Long Handoffs",
    "performance.long_handoffs_by_ap.column.total_handoffs": "Total Handoffs",
    "performance.long_handoffs_by_ap.column.long_handoff_rate": "Long Handoff Rate",
    "performance.long_handoffs_by_ap.column.grade": "Grade",
    "performance.long_handoffs_by_device.columns.id": "Device ID",
    "performance.long_handoffs_by_device.columns.avg_handoff_time": "AVG Handoff Time (ms)",
    "performance.long_handoffs_by_device.columns.long_handoff": "Total Long Handoffs",
    "performance.long_handoffs_by_device.columns.total_handoffs": "Total Handoffs",
    "performance.long_handoffs_by_device.columns.long_handoff_rate": "Long Handoff Rate",
    "performance.long_handoffs_by_ap.title": "Long Handoffs by AP",
    "performance.long_handoffs_by_device.title": "Long Handoffs by Device",
    "performance.long_handoff_summary.chart.total_long_handoffs": "Total Long Handoffs",
    "performance.long_rssi_events_by_ap.column.low_rssi_events": "Low RSSI Events",
    "performance.long_rssi_events_by_ap.column.total_call_minutes": "Total Call Minutes",
    "performance.long_rssi_events_by_ap.column.low_rssi_call_minute_percentage": "Low RSSI Events / Call Min",
    "performance.long_rssi_events_by_ap.column.grade": "Grade",
    "performance.long_rssi_events_by_device.column.id": "Device ID",
    "performance.long_rssi_events_by_device.column.low_rssi_events": "Low RSSI Events",
    "performance.long_rssi_events_by_device.column.total_call_minutes": "Total Call Minutes",
    "performance.long_rssi_events_by_device.column.low_rssi_call_minute_percentage": "Low RSSI Events / Call Min",
    "performance.long_rssi_events_by_ap.title": "Low RSSI (Received Signal Strength Indicator) Events by AP",
    "performance.long_rssi_events_by_device.title": "Low RSSI (Received Signal Strength Indicator) Events by Device",
    "performance.long_rssi_event_summary.chart.events": "Events",
    "performance.long_rssi_event_summary.chart.events_call_min": "Events / Call Min",
    "settings.title": "Settings",
    "settings.label.location": "Location",
    "common.select.choose": "--Please Choose--",
    "settings.panel.monthly_pdf_reports": "Monthly PDF Reports",
    "settings.panel.alert_notification_email": "Alert Notification Email",
    "settings.panel.amie_license_email": "AMiE License Email",
    "settings.panel.amie_page_refresh_frequency": "AMiE Page Refresh Frequency",
    "settings.panel.whitelisted _ssids": "Whitelisted SSID(s)",
    "settings.panel.disallowedlisted _ssids": "Disallowed List SSIDs",
    "settings.panel.battery_replacement_messages": "Battery Replacement Messages",
    "settings.panel.series_to_phone": "84-Series Message to Phone Settings",
    "settings.location.required": "Please choose a location",
    "settings.save.success": "Saved successfully",
    "settings.label.subject": "Subject",
    "settings.label.subject.required": "Please input Subject",
    "settings.label.message": "Message",
    "settings.label.message.required": "Please input Message",
    "settings.label.recipients": "Recipients",
    "settings.label.recipients.required": "Please input Recipients",
    "settings.label.save": "SAVE",
    "settings.label.dashboard": "Dashboard",
    "settings.label.dashboard.required": "Please select Dashboard",
    "settings.label.summary_pages": "Summary Pages",
    "settings.label.summary_pages.required": "Please select Summary Pages",
    "settings.label.detail_pages": "Detail Pages",
    "settings.label.detail_pages.required": "Please select Detail Pages",
    "settings.label.message_100": "Message (max. 1000 characters)",
    "settings.label.message_delivery_interval": "Message Delivery Interval",
    "settings.label.message_delivery_interval.required": "Please input Message Delivery Interval",
    "settings.label.minutes": "minutes",
    "settings.label.message_delivery_limit": "Message Delivery Limit",
    "settings.label.message_delivery_limit.required": "Please Choose",
    "settings.label.message_delivery_limit.text": "Limited: Stop sending messages after attempt",
    "settings.label.message_delivery_limit.text.required": "Please input value",
    "settings.label.message_delivery_limit.text2": "Persistent: Do not stop sending messages until battery is replaced",
    "settings.label.send_previous_report": "Send previous monthly report",
    "settings.label.send_current_report": "Send Current monthly report",
    "settings.label.username": "Username",
    "settings.label.username.required": "Please input Username",
    "settings.label.password": "Password",
    "settings.label.password.required": "Please input Password",
    "locations.tab.location_list": "Location List",
    "locations.tab.new_location": "New Location",
    "locations.tab.detail": "Detail",
    "locations.tab.floor": "Floor",
    "locations.label.location_contacts": "Location Contacts",
    "locations.label.contact": "Contact",
    "locations.label.contact.delete": "Are you sure you want to delete this contact?",
    "locations.button.add_contact": "Add Contact",
    "locations.modal.edit_contact.title": "Update Contact {a}",
    "locations.modal.add_contact.title": "Please enter the details for the new Contact",
    "locations.contact.label.first_name": "First Name",
    "locations.contact.label.first_name.required": "Please input First Name",
    "locations.contact.label.last_name": "Last Name",
    "locations.contact.label.last_name.required": "Please input Last Name",
    "locations.contact.label.phone": "Phone",
    "locations.contact.label.phone.required": "Please input Phone",
    "locations.contact.label.email": "Email",
    "locations.contact.label.email.required": "Please input email",
    "locations.columns.name": "Location Name",
    "locations.columns.address": "Location Address",
    "locations.columns.description": "Description",
    "locations.columns.create_date": "Create Date",
    "locations.columns.config_status": "Config Status",
    "locations.columns.location_contacts": "Location Contacts",
    "locations.label.name": "Location Name",
    "locations.label.number": "Location Number",
    "locations.label.address": "Location Address",
    "locations.label.tenant_id": "Tenant ID",
    "locations.label.timezone": "Timezone",
    "locations.label.end_of_24h_period": "End of 24h Period",
    "locations.label.ap_list": "AP List",
    "locations.bulk_load_ap.title": "Loaded Result",
    "locations.bulk_load_ap.total": "Total",
    "locations.bulk_load_ap.added": "Added",
    "locations.bulk_load_ap.updated": "Updated",
    "locations.bulk_load_ap.ignore": "Ignore (Exists In Other Location)",
    "locations.bulk_load_ap.errors": "Errors",
    "locations.bulk_load_ap.load_fail": "Load Fail",
    "common.button.loaded": "Loaded",
    "locations.bulk_load_ap.text": "NOTE the system expects a predetermined set of  columns. Please {a} our template if you are not sure what that structure is.",
    "locations.columns.floor_number": "Floor Number",
    "locations.columns.area_identifier": "Area Identifier",
    "locations.columns.floor_plan_loaded": "Floor Plan Loaded",
    "locations.columns.ap_assigned": "AP Assigned",
    "locations.floor.label.floor_number.message": "The value cannot be greater than 100.",
    "locations.floor.delete.text": "Are you sure you want to delete this floor?",
    "locations.floor.configured_floors": "Configured Floors",
    "locations.floor.modal.title": "Please enter the details for the new Floor below",
    "common.button.add_another": "Add Another",
    "locations.floor.label.floor_number": "Floor Number",
    "locations.floor.label.floor_number.required": "Please input Floor Number.",
    "locations.floor.label.area_identifier": "Area Identifier",
    "locations.columns.gateway_name": "Gateway Name",
    "locations.columns.gateway_size": "Gateway Size",
    "locations.columns.gateway_health": "Gateway Health",
    "locations.columns.last_check_in": "Last Check In",
    "spectralink_admin.tab.branding": "Branding",
    "spectralink_admin.tab.global_settings": "Global Settings",
    "spectralink_admin.tab.services_agreement": "Services Agreement",
    "spectralink_admin.tab.system_users": "System Users",
    "spectralink_admin.tab.system_roles": "System Roles",
    "spectralink_admin.tab.role_permission": "Role Permission",
    "spectralink_admin.tab.memory_usage": "Memory Usage",
    "admin.title": "Spectralink Admin",
    "spectralink_admin.branding.label.account": "Account",
    "spectralink_admin.branding.account.select": "--Please Choose--",
    "spectralink_admin.branding.label.logo": "Logo",
    "spectralink_admin.branding.label.upload_image": "Upload Image",
    "spectralink_admin.branding.button.reset_to_default_logo": "Reset To Default Logo",
    "spectralink_admin.branding.label.preview_image": "Preview Image",
    "spectralink_admin.branding.label.color_palette": "Color Palette",
    "spectralink_admin.branding.label.primary_color": "Primary Color",
    "spectralink_admin.branding.label.secondary_color": "Secondary Color",
    "spectralink_admin.branding.label.tertiary_color": "Tertiary Color",
    "spectralink_admin.branding.label.quaternary_color": "Quaternary Color",
    "spectralink_admin.branding.modal.edit_image": "Edit Image",
    "spectralink_admin.branding.modal.crop": "Crop",
    "spectralink_admin.services_agreement.title": "Services Agreement",
    "spectralink_admin.global_setting.generate": "Generate Settings",
    "spectralink_admin.global_setting.alert_threshold": "Alert Threshold Settings",
    "spectralink_admin.global_setting.column.name": "Name",
    "spectralink_admin.global_setting.column.type": "Type",
    "spectralink_admin.global_setting.column.trigger": "Trigger",
    "spectralink_admin.global_setting.column.description": "Description",
    "spectralink_admin.global_setting.column.threshold": "Threshold",
    "spectralink_admin.global_setting.column.threshold_type": "Threshold Type",
    "spectralink_admin.global_setting.column.modified": "modified",
    "spectralink_admin.global_setting.column.action": "Action",
    "spectralink_admin.global_setting.delete.text": "Are you sure you want to delete this Alert Threshold?",
    "spectralink_admin.global_setting.label.organization": "Organization",
    "spectralink_admin.global_setting.label.location": "Location",
    "spectralink_admin.global_setting.alert_threshold.add_another": "Add Other",
    "spectralink_admin.global_setting.alert_threshold.add_title": "Add Alert Threshold",
    "spectralink_admin.global_setting.alert_threshold.alert_threshold_name": "Alert Threshold Name",
    "spectralink_admin.global_setting.alert_threshold.alert_threshold_name.required": "Alert Threshold Name is required",
    "spectralink_admin.global_setting.alert_threshold.alert_threshold_trigger": "Alert Threshold Trigger",
    "spectralink_admin.global_setting.alert_threshold.alert_threshold_trigger.required": "Alert Threshold Trigger is required",
    "spectralink_admin.global_setting.alert_threshold.alert_threshold_description": "Alert Threshold Description",
    "spectralink_admin.global_setting.alert_threshold.alert_threshold_description.required": "Alert Threshold Description is required",
    "spectralink_admin.global_setting.alert_threshold.alert_threshold_type": "Alert Threshold Type",
    "spectralink_admin.global_setting.alert_threshold.alert_threshold_type.device": "Device",
    "spectralink_admin.global_setting.alert_threshold.alert_threshold_type.battery": "Battery",
    "spectralink_admin.global_setting.alert_threshold.alert_threshold_type.fleet": "Fleet",
    "spectralink_admin.global_setting.alert_threshold.alert_threshold_type.gateway": "Gateway",
    "spectralink_admin.global_setting.alert_threshold.threshold": "Threshold",
    "spectralink_admin.global_setting.alert_threshold.threshold.required": "Threshold is required",
    "spectralink_admin.global_setting.alert_threshold.alert_threshold_type.required": "Alert Threshold Type is required",
    "spectralink_admin.global_setting.alert_threshold.threshold_type": "Threshold Type",
    "spectralink_admin.global_setting.alert_threshold.threshold_type.number": "Number",
    "spectralink_admin.global_setting.alert_threshold.threshold_type.string": "String",
    "spectralink_admin.global_setting.alert_threshold.threshold_type.required": "Threshold Type is required",
    "spectralink_admin.global_setting.generate.data_aging_enabled": "Data Aging Enabled",
    "spectralink_admin.global_setting.generate.gateway_health_data_age": "Gateway Health Data Age",
    "spectralink_admin.global_setting.generate.days": "days",
    "spectralink_admin.global_setting.generate.elastic_search_data_age": "Elastic Search Data Age",
    "spectralink_admin.global_setting.generate.rds_data_age": "RDS Data Age",
    "spectralink_admin.global_setting.generate.out_of_contact_retirement_delay": "Out of Contact Retirement Delay",
    "spectralink_admin.global_setting.generate.manual_retirement_delay": "Manual Retirement Delay",
    "spectralink_admin.global_setting.generate.delay_data_collection_stop_on_license_expiry": "Delay Data Collection Stop on License Expiry",
    "spectralink_admin.global_setting.generate.delay_login_stop_on_license_expiry": "Delay Login Stop on License Expiry",
    "spectralink_admin.role.role_name": "Role Name",
    "spectralink_admin.role.role_type": "Role Type",
    "spectralink_admin.role.is_active": "Is Active",
    "spectralink_admin.role.creation_date": "Creation Date",
    "spectralink_admin.role.action": "Action",
    "spectralink_admin.role.set_permission": "Set Permission",
    "spectralink_admin.role.button.add": "ADD ANOTHER",
    "spectralink_admin.role.add_title": "system-role-Add",
    "spectralink_admin.user.name": "Name",
    "spectralink_admin.user.username": "Username",
    "spectralink_admin.user.role": "Role",
    "spectralink_admin.user.account_name": "Account Name",
    "spectralink_admin.user.phone_number": "Phone Number",
    "spectralink_admin.user.creation_date": "Creation Date",
    "spectralink_admin.user.last_login": "Last Login",
    "spectralink_admin.user.delete.text": "Are you sure you want to delete this user?",
    "spectralink_admin.user.spectralink_user.message": "You must have a @spectralink.com domain in the email to create a Spectralink user.",
    "spectralink_admin.user.add_another": "ADD ANOTHER",
    "spectralink_admin.user.add_spectralink_user": "Add Spectralink User",
    "spectralink_admin.user.phone": "Phone",
    "spectralink_admin.user.reset_password.success": "The reset password email has been sent. Please check your email.",
    "spectralink_admin.user.reset_password.error": "Server internal exception. Password reset failed.",
    "spectralink_admin.user.success_unlock": "Success Unlock",
    "spectralink_admin.user.edit_title": "Edit User - {a}",
    "spectralink_admin.user.button.reset_password": "Reset Password",
    "spectralink_admin.user.reset_password.text": "Please confirm that you want to reset this user's password",
    "spectralink_admin.user.button.unlock": "Unlock",
    "change_password.password.error": "The passwords do not match",
    "change_password.password.length_error": "must have length greater than or equal to 8!",
    "change_password.password.lowercase_error": "Password must have lowercase characters!",
    "change_password.password.uppercase_error": "Password must have uppercase character!",
    "change_password.password.symbol_error": "Password must have symbol characters(.!@#$%^&*)!",
    "change_password.title": "Change Password",
    "change_password.subtitle": "Please create your new password.",
    "change_password.password": "Temporary Password",
    "change_password.password.required": "Please input your Temporary Password!",
    "change_password.newpassword": "New Password",
    "change_password.newpassword.required": "Please input your password!",
    "change_password.confirmPassword": "Confirm New Password",
    "change_password.confirmPassword.required": "Confirmed Password is required!",
    "forget_password.submit_success": "Verification code sent to email",
    "forget_password.title": "Need help with your password?",
    "forget_password.subtitle": "Enter the email you use for AMIE, and we'll send an email with verification code.",
    "forget_password.email": "Email Address",
    "forget_password.email.required": "Please input your email address!",
    "forget_password.reset": "Reset",
    "login.title": "Sign in to AMIE Console",
    "login.subtitle": "Enter your details below",
    "login.username": "Username",
    "login.username.required": "Please input your username!",
    "login.password": "Password",
    "login.password.required": "Please input your Password!",
    "login.sign_up": "Sign-Up",
    "login.forgot_password": "Forgot password ?",
    "login.sign_in": "SIGN IN",
    "password_reset.title": "Reset Password",
    "password_reset.subtitle": "Please create your new password.",
    "password_reset.password": "Old Password",
    "password_reset.password.required": "Please input your password!",
    "password_reset.new_password": "New Password",
    "password_reset.new_password.required": "Please input your password!",
    "password_reset.confirm_password": "Confirm New Password",
    "password_reset.confirm_password.required": "Confirmed Password is required!",
    "password_reset.reset": "Reset",
    "profile.title": "Profile",
    "profile.settings.label.old_password": "Old Password",
    "profile.settings.label.old_password.required": "Password is required",
    "profile.settings.label.new_password": "New Password",
    "profile.settings.label.new_password.required": "New password is required",
    "profile.settings.label.confirm_password": "Confirm Password",
    "profile.settings.label.confirm_password.required": "Confirm Password is required",
    "common.button.update": "Update",
    "profile.settings.label.username": "Username",
    "profile.settings.label.name": "Name",
    "profile.settings.label.name.required": "Name is required",
    "profile.settings.label.phone": "Phone",
    "profile.settings.label.phone.required": "Phone is required",
    "profile.settings.button.update_profile": "Update Profile",
    "reset_password.title": "Reset Password",
    "reset_password.subtitle": "Please enter the new password. The verification code has been sent to your email.",
    "reset_password.password": "Password",
    "reset_password.password.required": "Please input your password!",
    "reset_password.confirm_password": "Confirm Password",
    "reset_password.confirm_password.required": "Confirmed Password is required!",
    "reset_password.code": "Verification Code",
    "reset_password.code.required": "Please input your Code!",
    'reset_password.code.text': 'The verification code in the email',
    "common.button.reset": "Reset",
    "sign_up.license.title1": "Welcome to the AMiE {br} Customer Sign-Up Portal",
    "sign_up.license.title2": " Please enter the License Key details that you received via email  below and {br} then click SUBMIT",
    "sign_up.license.label.license_key": "License Key",
    "sign_up.license.button.submit": "SUBMIT",
    "sign_up.initial_user.title": "The first step is to create the AMiE Super User for your account",
    "sign_up.initial_user.label.name": "Name",
    "sign_up.initial_user.label.phone": "Phone",
    "sign_up.initial_user.label.email": "Super User Email",
    "sign_up.initial_user.label.send_email.text": "Two emails have been sent to this email address from donotreply@spectralink.net to verify the email and setup a password. Please first verify the password and then setup a password after.{br} Note: If you don't see one or both of the emails, thenplease check your junk mail folders.",
    "sign_up.initial_user.label.send_email.text2": "The system will send a tokenized email to this user which will allow them to access the AMiE Console and complete the creation of their account.",
    "sign_up.initial_user.buttpn.send_email": "Send Welcome Email",
    "sign_up.create_location.modal.title11": "Create new Location: Step 1 of 3 Location Details",
    "sign_up.create_location.modal.titlel2": "Create new Location: Step 2 of 3 Gateway Sizing",
    "sign_up.create_location.modal.titlel3": "Create new Location: Step 3 of 3 Gateway Details",
    "sign_up.create_location.label.site_name": "Gateway Name",
    "sign_up.create_location.label.site_name.required": "Please input Gateway Name",
    "sign_up.create_location.label.site_size": "Site Size",
    "sign_up.create_location.label.site_size.required": "Please select an site size",
    "sign_up.create_location.label.self_signed_cert": "Self Signed Cert",
    "sign_up.create_location.label.self_signed_cert.required": "Please select Self Signed Cert",
    "sign_up.create_location.label.ca_cert": "CA Cert",
    "sign_up.create_location.label.ca_cert.required": "Please input CA Cert",
    "sign_up.create_location.label.mqtts_cert": "MQTTS Cert",
    "sign_up.create_location.label.mqtts_cert.required": "Please input MQTTS Cert",
    "sign_up.create_location.label.mqtts_private_key": "MQTTS Private Key",
    "sign_up.create_location.label.mqtts_private_key.required": "Please input MQTTS Private Key",
    "sign_up.create_location.button.back": "Back",
    "sign_up.create_location.button.confirm": "Confirm",
    "sign_up.create_location.label.location_name": "Location Name",
    "sign_up.create_location.label.location_number": "Location Number",
    "sign_up.create_location.label.address": "Address",
    "sign_up.create_location.label.city": "City",
    "sign_up.create_location.label.state_province": "State/Province",
    "sign_up.create_location.label.country": "Country",
    "sign_up.create_location.label.zip_postal_code": "Zip/Postal Code",
    "sign_up.create_location.label.time_zone": "Time Zone",
    "sign_up.create_location.label.end_of_24h_period": "End of 24h Period",
    "sign_up.create_location.button.next": "Next",
    "sign_up.setup_options.column.site_name": "Site Name",
    "sign_up.setup_options.column.site_address": "Site Address",
    "sign_up.setup_options.column.gateway_name": "Gateway Name",
    "sign_up.setup_options.column.gateway_size": "Gateway Size",
    "sign_up.create_locations.title": "The second step in this configuration process is to create the Locations list for your organization and estimate the number of devices that will be used at each location.",
    "sign_up.create_locations.button.add": "Add Location",
    "sign_up.create_locations.button.configured_sites": "Configured Sites",
    "sign_up.create_locations.button.add_another": "ADD ANOTHER",
    "sign_up.create_locations.button.confirm": "Confirm",
    "sign_up.create_locations.modal.text": "The configuration from this point forward focuses on the Creation of the initial Locations list and then verifying the AMiE gateway configuration for your  Organization. You can continue and complete this process now or you may delegate this Role to the Super User that you created on the previous page and they can thencomplete this process on your behalf.",
    "sign_up.create_locations.modal.button.complate": "I will Complete",
    "sign_up.create_locations.modal.button.assign": "Assign to SuperUser",
    "sign_up.create_locations.modal.text2": "We have sent a tokenized email to the SuperUser with instructions on how to complete the Onboarding process for your Organization. Once the Onboarding has been completed you will receive an email confirmation to close out this process.",
    "sign_up.create_locations.modal.button.close": "Close",
    "sign_up.password.title1": "Welcome to the AMiE Customer Portal",
    "sign_up.password.title2": "You have been identified as a SuperUser with AMiE for",
    "sign_up.password.title3": "The next step is for you to create your password for the portal.",
    "sign_up.password.label.password": "Password",
    "sign_up.password.label.password.required": "Password is required",
    "sign_up.password.label.confirm_password": "Confirm Password",
    "sign_up.password.label.password_recovery": "Password Recovery",
    "sign_up.password.button.submit": "SUBMIT",
    "sign_up.summary.title1": "Welcome to AMiE",
    "sign_up.summary.title2": "Let's get started on configuring your system for operation. Below you will see a summary of what has been set for your account",
    "sign_up.summary.label.account_id": "Account ID",
    "sign_up.summary.label.account_name": "Account Name",
    "sign_up.summary.label.license_start": "License Start",
    "sign_up.summary.label.license_end": "License End",
    "sign_up.summary.label.amie_package": "AMiE Package",
    "sign_up.summary.label.number_of_devices": "Number of Devices",
    "sign_up.summary.text1": "Select a unique name for your organization",
    "sign_up.summary.text2": " (Additional Organizations can be set within the system)",
    "sign_up.summary.label.organization_name": "Organization Name",
    "sign_up.summary.label.organization_name.required": "Organization Name is required",
    "sign_up.summary.text3": "Select Geographical Region to deploy your AMiE System",
    "sign_up.summary.label.deployment_region": "Deployment Region",
    "sign_up.summary.text4": "By proceeding you Agree to the AMiE {a}",
    "sign_up.summary.label.terms_conditions": "Terms and Conditions",
    "sign_up.summary.label.yes_proceed": "Yes, Proceed",
    "sign_up.summary.label.yes_proceed.required": "You have to agree before you can proceed",
    "sign_up.summary.button.start_configuration_process": "Start Configuration Process",
    "licenses.label.account_name": "Account Name",
    "licenses.label.account_id": "Account ID",
    "licenses.label.order_number": "Order Number",
    "licenses.label.order_date": "Order Date",
    "licenses.label.from_date": "From Date",
    "licenses.label.to_date": "To Date",
    "licenses.label.amie_package": "AMiE Package",
    "licenses.label.number_of_devices": "Number of Devices",
    "licenses.title": "Licenses",
    "licenses.label.allocated_licenses": "Allocated licenses",
    "licenses.button.add_license": "ADD LICENSE",
    "licenses.column.location_name": "Location Name",
    "licenses.column.allocation": "Allocation",
    "licenses.column.white_list_devices": "White List Devices",
    "licenses.column.other_licenses": "Other Licenses",
    "licenses.column.order_number": "Order Number",
    "licenses.column.order_date": "Order Date",
    "licenses.column.from_date": "From Date",
    "licenses.column.to_date": "To Date",
    "licenses.column.device_licenses": "# Device Licenses",
    "licenses.column.package": "AMiE Package",
    "licenses.column.license_status": "License Status",
    "licenses.column.email": "Email",
    "licenses.column.account_id": "Account ID",
    "licenses.column.order_id": "Order ID",
    "licenses.column.message": "Message",
    "licenses.column.notificatioin_date": "Notificatioin Date",
    "licenses.label.add_license.text1": "Please enter the additional License Key details that you received via email below and then click SUBMIT",
    "licenses.label.add_license.label.key": "License Key",
    "licenses.label.add_license.button.submit": "SUBMIT",
    "licenses.label.add_license.modal.title": "Add License",
    "licenses.label.add_license.text2": "Please review the License details below and click SUBMIT to have this License loaded to the  system. You will then be able to review the License details and determine where this License should be applied",
    "licenses.label.add_license.text3": "Here is what we have decoded for your License",
    "licenses.label.license_start": "License Start",
    "licenses.label.license_end": "License End",
    "licenses.allocation.button.modify": "MODIFY LICENSE ALLOCATION",
    "licenses.allocation.modal.title": "License Allocation for this Order ID across Locations",
    "licenses.allocation.label.location_name": "Location Name",
    "licenses.allocation.label.location_name.required": "Please input Location Name",
    "licenses.allocation.label.allocated_devices": "Allocated Devices",
    "licenses.allocation.label.allocated_devices.required": "Please input Allocated Devices",
    "licenses.allocation.label.self_signed_cert": "Self Signed Cert",
    "licenses.allocation.label.self_signed_cert.required": "Please select Self Signed Cert",
    "licenses.allocation.button.create": "CREATE",
    "licenses.allocation.button.add": "ADD ANOTHER LOCATION",
    "licenses.allocation.button.save": "SAVE",
    "licenses.label.update_license.text": "Please enter the License Key details that you received via email below and {br} then click SUBMIT",
    "licenses.label.update_license.label.key": "License Key",
    "licenses.label.update_license.button.submit": "SUBMIT",
    "licenses.label.update_license.modal.text": "An issue has occurred during the loading of your License Key.  Please contact Customer Support at 1-888-###-### and report that you received the following error code",
    "licenses.label.update_license.label.error_code": "ERROR CODE",
    "licenses.label.update_license.text2": "Congratulations - your renewed License has been loaded and will become active when the existing License expires on {expiresDate}",
    "licenses.label.update_license.button.continue": "CONTINUE",
    "licenses.label.update_license.text3": "Please review the License details below and then accept to enable this License to Replace the existing License when the former expires",
    "licenses.label.update_license.text4": "Here is what we have decoded for your License",
    "licenses.label.update_license.text5": "Please update {licenseId} on {expiresDate} ",
    "menu.group_monitoring_t": "Monitoring",
    "menu.spectralink-admin": "Spectralink Admin",
    "menu.administration.data-retention": "Data Retention",
    "menu.administration.timezone": "Timezone",
    "menu.administration.admin-users": "Users",
    "menu.administration.devicesbatteries": "Devices/Batteries",
    "menu.dashboard": "Dashboard",
    "menu.alert": "Alert",
    "menu.performance": "Performance",
    "menu.group_handsets_t": "Handsets",
    "menu.device": "Devices",
    "menu.battery": "Batteries",
    "menu.group_infrastructure_t": "Infrastructure",
    "menu.gateways": "Gateways",
    "menu.group_hierarchy_t": "Hierarchy",
    "menu.accounts": "Accounts",
    "menu.organizations": "Organizations",
    "menu.locations": "Locations",
    "menu.group_amie_settings_t": "AMIE Settings",
    "menu.settings": "Settings",
    "menu.administration": "Administration",
    "menu.administration.license": "License",
    "common.label.choose": "--Please choose--",
    "common.label.organization": "Organization",
    "common.label.all_locations": "All locations",
    "common.label.account": "Account",
    "common.page.privacy_policy": "Privacy Policy",
    "common.page.terms_of_use": "Terms of Use",
    "organizations.columns.name": "Organization Name",
    "common.avatar.settings": "Settings",
    "common.avatar.sign_Out": "Sign Out",
    "common.avatar.profile": "Profile",
    "batteries.top_android_apps": "Top Android Apps",
    "batteries.cycle_counts": "Cycle Counts",
    "devices.operating_system": "Operating System",
    "performance.call_performance_metrics_by_device.columns.device_id": "Device ID",
    "locations.title": "Locations",
    "spectralink_admin.title": "Spectralink Admin",
    "common.range.tag": "Tag",
    "spectralink_admin.role.permissions": "Permissions",
    "spectralink_admin.memory_usage.label.heap_tatal": "Heap Total",
    "spectralink_admin.memory_usage.label.heap_used": "Heap Used",
    "spectralink_admin.memory_usage.label.heap_free": "Heap Free",
    "locations.floor.label.floor": "FLOOR",
    "locations.floor.ap_assigned.label.bssid": "BSSID",
    "locations.floor.ap_assigned.label.bssid.required": "Please input BSSID",
    "locations.floor.ap_assigned.label.ap_name": "AP Name",
    "locations.floor.ap_assigned.label.ap_name.required": "Please input AP Name",
    "locations.floor.ap_assigned.button.assign": "Assign And Save",
    "locations.floor.ap_assigned.radio.select": "Select form list",
    "locations.floor.ap_assigned.radio.enter": "Enter Ap Details",
    "locations.floor.ap_assigned.label.stepone": "Step One - Drop Pin",
    "locations.floor.ap_assigned.button.start": "Start",
    "locations.floor.ap_assigned.label.steptwo": "Step Two - Assign AP",
    "locations.floor.ap_assigned.label.desc": "Choose to select AP details and SAVE",
    "locations.floor.ap_assigned.button.save": "Save And Exit",
    "locations.floor.ap_assigned.button.back": "Back",
    "locations.floor.label.floor_plan": "Floor plan?",
    "locations.floor.label.yes": "YES",
    "locations.floor.label.no": "NO",
    "locations.floor.label.upload": "UPLOAD",
    "locations.floor.button.review": "REVIEW",
    "locations.floor.label.ap_assigned": "AP Assigned?",
    "locations.floor.button.assign": "ASSIGN",
    "locations.floor.label.Save": "Save",
    "locations.floor.label.Back": "Back",
    "locations.floor_map.tooltip.label.bssid": "BSSID",
    "locations.floor_map.tooltip.label.ap_name": "AP Name",
    "locations.floor_map.tooltip.label.unassigned": "Unassigned",
    "locations.floor_map.tooltip.label.x_postion": "X Postion",
    "locations.floor_map.tooltip.label.y_postion": "Y Postion",
    "locations.floor_map.tooltip.delete": "Are you sure you want to delete this AP?",
    "gateways.label.gateway_name": "Gateway Name",
    "gateways.label.gateway_name.required": "Please input Gateway Name",
    "gateways.configuration.button.gateway_create_command": "Regenerate Create Gateway command",
    "gateways.configuration.gateway_create_command.text": "The Gateway command is valid before",
    "gateways.configuration.gateway_create_command.text2": "Gateway command expired – click to regenerate a new command",
    "gateways.configuration.panel.gateway_configuration": "Gateway Configuration",
    "gateways.configuration.panel.gateway_activity": "Gateway Activity",
    'devices.send_message_label': 'Send Message (Maximum 255 characters)',
    'devices.send_message_label.error': 'Message too long - Cannot exceed 255 characters.',
    "devices.summary.label.utilized": "Utilized",
    "devices.summary.label.unutilized": "Unutilized",
    "batteries.last_location.title": "Last Location",
    "batteries.last_location.label.device_serial_no.": "Device Serial No.",
    "batteries.last_location.label.last_seen": "Last Seen",
    "batteries.last_location.last_ssid": "Last SSID",
    "batteries.last_location.label.location": "Location",
    "batteries.last_location.label.floor": "Floor",
    "batteries.label.decommission_battery": "Decommission Battery",
    "batteries.label.last_known_location": "Last Known Location",
    "batteries.label.last_known_device": "Last Known Device",
    "batteries.label.battery_currently_in_use": "Battery Currently in Use",
    "batteries.label.message": "Message to Display to any users that use this battery",
    "batteries.label.message.required": "Message is required.",
    'devices.battery_history': 'Battery History',
    "batteries.label.serial": "Serial #",
    "batteries.button.send_to_device": "SEND TO DEVICE",
    "batteries.label.date": "Date",
    "batteries.label.usage_start": "Usage Start",
    "batteries.label.usage_end": "Usage End",
    "batteries.label.last_ap": "Last AP",
    "batteries.label.last_device": "Last Device",
    'batteries.tab.summary': 'Summary',
    'batteries.tab.details': 'Details',
    "locations.creat.label.location_name": "Location Name",
    "locations.creat.label.location_number": "Location Number",
    "locations.creat.label.address": "Address",
    "locations.creat.label.city": "City",
    "locations.creat.label.state_province": "State/Province",
    "locations.creat.label.country": "Country",
    "locations.creat.label.zip_postal_code": "Zip/Postal Code",
    "locations.creat.label.zip_postal_code.required": "Zip/Postal Code is required.",
    "locations.creat.label.time_zone": "Time Zone",
    "locations.creat.label.time_zone.required": "Time Zone is required",
    "locations.creat.label.end_of_24h_period": "End of 24h Period",
    "locations.creat.label.end_of_24h_period.tip": "Note: Used to aggregate daily stats over this 24h period.",
    "locations.creat.gateway_setup.text1": "1. Install the AMIE Gateway Open Virtual Appliance (OVA) image on a Virtual Machine (VM) host.{br}2. After the gateway image has been installed, become root by executing the following command at the shell prompt.{br}{cmd}",
    "locations.creat.gateway_setup.curl": "3. Copy and paste the curl command provided below at the shell prompt. The curl command below will only be valid for 24 hours. If curl command expires, then please refer to the AMiE user guide to generate a new curl command.",
    "locations.creat.gateway_setup.text2": "For AMiE to work, Spectralink devices must be able to reach the gateway VM on port 8883 ",
    "locations.creat.gateway_setup.text3": "For AMiE to work, firewall policies must allow the gateway VM to communicate with the following URLs",
    "locations.creat.gateway_setup.location": "Location",
    "locations.creat.gateway_setup.gateway": "Gateway",
    "locations.creat.gateway_setup.curl_command": "Curl Command",
    "locations.creat.gateway_setup.copy_to_clipboard": "Copy to Clipboard",
    "locations.creat.gateway_setup.ca_cert": "CA Cert",
    "locations.creat.gateway_setup.mqtts_certificate": "MQTTS Certificate",
    "locations.creat.gateway_setup.text4": "This certificate is a configuration parameter for the AMiE Agent on the Spectralink smartphone. The certificate is used to provide encrypted communication between the phone and the AMiE Gateway. The AMiE Agent’s configuration parameter  can be set through either Spectralink’s SAM Application or through most MDM applications. Copy and paste the entire certificate below into the AMiE Agent configuration parameter field called MQTTS Certificate in either SAM or your MDM application. If you choose to save this certificate for future  reference, then please store the certificate information in a secure location.",
    "locations.creat.gateway_setup.mqtts_private_key": "MQTTS Private Key",
    "locations.creat.label.gateway_name": "Gateway Name",
    "locations.creat.label.gateway_name.required": "Please input Gateway Name",
    "locations.creat.label.ip.address" : "IP Address",
    "locations.creat.label.ip.address.required":"Please Provide IP Address",
    "locations.creat.label.mask.detail" :"Mask",
    "locations.creat.label.gwDetail" : "Gateway",
    "locations.creat.label.dnsDetails" : "DNS",
    "locations.creat.label.gateway_cert_info": "Certificate Information",
    "locations.creat.label.gateway_cert_info_ip_required": "Valid IP required",
    "locations.creat.label.gateway_cert_info_dns_required": "Valid hostname required",
    "locations.creat.label.site_size": "Site Size",
    "locations.creat.label.site_size.required": "Please select an site size",
    "locations.creat.label.self_signed_cert": "Self Signed Cert",
    "locations.creat.label.self_signed_cert.required": "Please select Self Signed Cert",
    "locations.creat.label.ca_cert": "CA Cert",
    "locations.creat.label.ca_cert.required": "Please input CA Cert",
    "locations.creat.label.mqtts_cert": "MQTTS Cert",
    "locations.creat.label.mqtts_cert.required": "Please input MQTTS Cert",
    "locations.creat.label.mqtts_private_key": "MQTTS Private Key",
    "locations.creat.label.mqtts_private_key.required": "Please input MQTTS Private Key",
    "locations.creat.gateway_setup.title1": "Create new Location: Step 1 of 4 Location Details",
    "locations.creat.gateway_setup.title2": "Create new Location: Step 2 of 4 Gateway Image",
    "locations.creat.gateway_setup.title3": "Create new Location: Step 3 of 4 Gateway Details",
    "locations.creat.gateway_setup.title4": "Create new Location: Step 4 of 4 Gateway Setup",
    "profile.edit.button": "Edit Profile",
    "change_password.update.success": "Your password has been changed",
    "profile.tabs.profile": "Profile",
    "profile.tabs.change_password": "Change Password",
    "common.update.success": "Update Success",
    "devices_batteries.column.phone": "Phone Name",
    "devices_batteries.column.sipExt": "SIP Ext",
    "devices_batteries.column.last_known_location": "Last Known Location",
    "devices_batteries.label.location_license_summary": "Location License Summary",
    "devices_batteries.text.device_licenses_aaocated": "{a} of {b} device licenses allocated",
    "common.table.pagination": "Records {a} - {b} of {c}",
    "common.table.view": "View"
    
};
