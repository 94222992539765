import { AppConstants } from '../../constants/constants';

export const initState = {
    isLoading: false,
    init: false,
    title: AppConstants.TITLE,
    currentCustomer: '',
    organization: '',
    location: '',
    customers: [],
    sites: [],
    groups: [],
    currentActiveMenu: 'dashboard', // xxx.xxx
    menus: [],
    maintennance: '',
    spectralinkAdmin: {},
    language: 'en-US',
    languageTexts: [],
    alertThreshold: {},
    adminAcount: '',
    adminOrganization: '',
    notificationTimeRange: 24 * 7,
};
const basicReducer = (state = initState, action) => {
    switch (action.type) {
        case AppConstants.LOADING_START: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case AppConstants.LOADING_END: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case AppConstants.INIT_APP: {
            return {
                ...state,
                init: true,
            };
        }
        case AppConstants.SET_APP_LANGUAGE: {
            return {
                ...state,
                language: action.payload,
            };
        }
        case AppConstants.SET_APP_LANGUAGE_TEXTS: {
            return {
                ...state,
                languageTexts: action.payload,
            };
        }
        case AppConstants.CURRENT_CUSTOMER: {
            return {
                ...state,
                currentCustomer: action.data,
            };
        }
        case AppConstants.SET_MAINTENNANCE: {
            return {
                ...state,
                maintennance: action.payload,
            };
        }
        case AppConstants.GET_SPECTRALINK_ADMIN: {
            return {
                ...state,
                spectralinkAdmin: action.payload,
            };
        }
        case AppConstants.UPDATE_SPECTRALINK_EULA: {
            return {
                ...state,
                spectralinkAdmin: {
                    ...state.spectralinkAdmin,
                    eula: action.payload,
                },
            };
        }
        case AppConstants.UPDATE_SPECTRALINK_BRANDING: {
            return {
                ...state,
                spectralinkAdmin: {
                    ...state.spectralinkAdmin,
                    ...action.payload,
                },
            };
        }

        case AppConstants.SET_TITLE: {
            document.title = action.data;
            return {
                ...state,
                title: action.data,
            };
        }
        case AppConstants.LOAD_CUSTOMERS: {
            return {
                ...state,
                customers: action.data,
            };
        }
        case AppConstants.LOAD_SITES: {
            return {
                ...state,
                sites: action.data,
            };
        }
        case AppConstants.LOAD_ALERT_THRESHOLD_TABLE: {
            return {
                ...state,
                alertThreshold: action.data,
            };
        }

        case AppConstants.SET_CURRENT_ACTIVE_MENU: {
            return {
                ...state,
                currentActiveMenu: action.payload,
            };
        }
        case AppConstants.SET_CURRENT_PAGE_INFO: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case AppConstants.CURRENT_ORGANIZATION: {
            return {
                ...state,
                organization: action.payload,
            };
        }
        case AppConstants.CURRENT_LOCATION: {
            return {
                ...state,
                location: action.payload,
            };
        }
        case AppConstants.CURRENT_ACCOUNT: {
            return {
                ...state,
                currentAccountId: action.payload,
            };
        }
        case AppConstants.ADMIN_ACCOUNT: {
            return {
                ...state,
                adminAcount: action.payload,
            };
        } 
        case AppConstants.ADMIN_ORGANIZATION: {
            return {
                ...state,
                adminOrganization: action.payload,
            };
        }
        case AppConstants.SET_MENUS: {
            return {
                ...state,
                menus: action.payload,
            };
        }
        case AppConstants.SET_NOTIFICATION_TIME_RANGE: {
            return {
                ...state,
                notificationTimeRange: action.payload,
            };
        }

        default:
            return state;
    }
};
export default basicReducer;
