import React, { Component } from 'react';
import { Button } from 'antd';
import { FormContext } from '../context';

export default class SubmitBtn extends Component {
    static contextType = FormContext;

    getDisabled = () => {
        const { isDisabeld } = this.props;
        if (!isDisabeld) {
            return false;
        }
        if (isDisabeld === true) {
            return true;
        }
        const values = this.context.form.getFieldsValue();
        return isDisabeld(values);
    };

    render() {
        const { children, id } = this.props;
        return (
            <Button
                id={id || 'btn_submit'}
                type="primary"
                htmlType="submit"
                disabled={this.getDisabled()}
            >
                {children}
            </Button>
        );
    }
}
