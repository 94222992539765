import { userConstants, roleConstants } from "../../constants/constants";

const initState = {
    userTable: {
        data: [],
        filter: {},
        sort: {
            field: "grouping",
            order: "descend"
        }
    },
    roleTable: {
        data: [],
        filter: {},
        sort: {
            field: "grouping",
            order: "descend"
        }
    }
}
const userReducer = (state = initState, action) => {
    switch (action.type) {
        case userConstants.LOAD_USER_TABLE: {
            return {
                ...state,
                userTable: action.data
            }
        }
        case roleConstants.LOAD_ROLE_TABLE: {
            return {
                ...state,
                roleTable: action.data
            }
        }
        default:
            return state;
    }
}
export default userReducer;