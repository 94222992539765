import { licensesConstants } from "../../constants/constants";

const initState = {
    table: {
        filter: {},
    },
    summary: {

    }
}
const licensesReducer = (state = initState, action) => {
    switch (action.type) {
        case licensesConstants.LOAD_LICENSES_TABLE: {
            return {
                ...state,
                table: action.data
            }
        }
        case licensesConstants.GET_LICENSE_SUMMARY: {
            return {
                ...state,
                summary: action.payload
            }
        }
        default:
            return state;
    }
}
export default licensesReducer;